import { useEffect } from 'react';
import { CurrencyFormatter } from '../../../helpers/utils';
import { Warning } from '../../Icons/Warning';
import Button from '../../ui/button';
import Modal from '../modal';
import { App } from '@capacitor/app';

export const PixPending = ({ open, onClose, valuePending }: any) => {
	useEffect(() => {
		App.addListener('backButton', (event) => {
			if (open) {
				onClose();
			}
		});

		return () => document.body.classList.remove('overflow-hidden');
	}, [open]);

	return (
		<Modal
			id="pixPending"
			open={open}
			onClose={() => {
				onClose();
			}}
		>
			<div className="flex flex-col gap-6 items-center">
				<Warning />

				<p className="text-2xl font-bold text-center w-96 text-custom-gray2 font-bahnschrift">
					Você tem uma solicitação de saque em aberto
				</p>
				<p className="w-80 text-sm text-center text-custom-gray2 font-bahnschrift">
					Já existe solicitação de saque PIX em andamento no valor de
					R$ {CurrencyFormatter(Number(valuePending))}. Aguarde
					finalização da mesma para solicitar novo saque
				</p>

				<div className="w-full pt-2">
					<Button
						onClick={() => {
							onClose();
						}}
						className={'w-full h-12'}
					>
						Fechar
					</Button>
				</div>
			</div>
		</Modal>
	);
};
