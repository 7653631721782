import { useEffect, useRef, useState } from 'react';
import { RiCheckLine, RiPencilFill } from '@remixicon/react';
import TextInput from '../../../../../components/ui/TextInput';
import Button from '../../../../../components/ui/button';
import Modal from '../../../../../components/Modal/modal';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { UpdatePassword } from '../../../../../types/userData/validation';
import { helpButton } from '../../../../../components/ui/helpButton';
import { sentryCapture } from '../../../../../helpers/utils';
import { SentryError } from '../../../../../types/enums';
import { useApi } from '../../../../../hooks/useApi';
import { toast } from 'sonner';
import Toast from '../../../../../components/ui/toast';
import { useTokenStore } from '../../../../../store/token';

export const EditPassword = () => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const [activeStep, setActiveStep] = useState(1);

	const step2 = useRef<HTMLInputElement>(null);
	const step3 = useRef<HTMLInputElement>(null);

	const steps = [
		{ step: 1, title: 'Informe a sua senha' },
		{ step: 2, title: 'Informe a nova senha' },
		{ step: 3, title: 'Confirme a nova senha' },
	];

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<z.infer<typeof UpdatePassword>>({
		resolver: zodResolver(UpdatePassword),
		mode: 'onChange',
		defaultValues: {
			oldPassword: '',
			password: '',
			confirmPassword: '',
		},
	});

	useEffect(() => {
		if (activeStep === 2 && step2.current) {
			step2.current.focus();
		}
		if (activeStep === 3 && step3.current) {
			step3.current.focus();
		}
	}, [activeStep]);

	useEffect(() => {
		if (!openModal) {
			setActiveStep(1);
			reset({ password: '' });
			reset({ confirmPassword: '' });
		}
	}, [openModal]);

	const handleUpdate = async (data: z.infer<typeof UpdatePassword>) => {
		setIsLoading(true);
		try {
			const response = await api.updatePassword(
				{ oldPassword: data.oldPassword, newPassword: data.password },
				token
			);
			if (!response?.success) {
				throw new Error(response.message);
			}

			setActiveStep(1);
			reset({ password: '' });
			reset({ confirmPassword: '' });
			setOpenModal(!openModal);
			toast(<Toast variant="success">Senha Alterada com sucesso!</Toast>);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const stepForm = (step: number) => {
		switch (step) {
			case 1:
				return (
					<Controller
						control={control}
						name="oldPassword"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="password"
									placeholder="Digite a senha"
									value={value}
									error={errors?.oldPassword?.message}
									onChange={onChange}
								/>
								<div className="flex gap-2">
									<Button
										onClick={() => setActiveStep(step + 1)}
										disabled={
											!value ||
											!!errors?.oldPassword ||
											isLoading
										}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Próximo
									</Button>
								</div>
							</div>
						)}
					/>
				);
			case 2:
				return (
					<Controller
						control={control}
						name="password"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="password"
									placeholder="Digite a senha"
									value={value}
									error={errors?.password?.message}
									onChange={onChange}
									ref={step2}
									autoFocus
								/>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => setActiveStep(step - 1)}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										onClick={() => setActiveStep(step + 1)}
										disabled={
											!value ||
											!!errors?.password ||
											isLoading
										}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Próximo
									</Button>
								</div>
							</div>
						)}
					/>
				);
			case 3:
				return (
					<Controller
						control={control}
						name="confirmPassword"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="password"
									placeholder="Digite a senha novamente"
									value={value}
									error={errors?.confirmPassword?.message}
									onChange={onChange}
									ref={step3}
									autoFocus
								/>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => setActiveStep(step - 1)}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										type="submit"
										disabled={
											!value || !!errors?.confirmPassword
										}
										isLoading={isLoading}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Confirmar
									</Button>
								</div>
							</div>
						)}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div className="flex items-center gap-4">
			<div className="flex-1">
				<TextInput
					type="text"
					placeholder="Altere sua senha"
					value={''}
					onChange={() => {}}
					disabled
				/>
			</div>

			<Button
				onClick={() => setOpenModal(!openModal)}
				variant="help"
				className={'w-12 h-12'}
			>
				<RiPencilFill size={24} color="#3A3D44" className={''} />
			</Button>

			<Modal
				id="password"
				open={openModal}
				onClose={() => setOpenModal(!openModal)}
			>
				<div className="">
					<p className="text-2xl font-bold text-left text-custom-gray2 pb-5 font-bahnschrift">
						Atualizar senha
					</p>

					<form
						onSubmit={handleSubmit(handleUpdate)}
						className="mb-7 flex flex-col"
					>
						<ol className="relative mx-4">
							{steps.map((item, index) => (
								<li
									key={index}
									className="relative flex flex-col"
								>
									<div className="flex items-start relative">
										<div className="flex flex-col items-center -start-4">
											<span
												className={`absolute z-10 flex items-center justify-center font-bahnschrift w-7 h-7 rounded-full ring-custom-white ${
													item.step <= activeStep
														? 'bg-primary text-custom-white'
														: 'bg-custom-gray5 text-custom-gray2'
												}`}
											>
												{item.step < activeStep ? (
													<RiCheckLine
														size={20}
														color="#ffffff"
														className=""
													/>
												) : (
													item.step
												)}
											</span>
											<div
												className={`text-gray-500 flex border-s w-[1px] absolute ${
													item.step < activeStep
														? 'border-primary h-full -z-10'
														: activeStep ===
																item.step &&
														  activeStep <
																steps.length
														? 'border-primary h-full'
														: item.step <=
																steps.length -
																	1 &&
														  'border-custom-gray5 h-10'
												}`}
											></div>
										</div>

										<div className="flex-1 ml-8 mb-4">
											<h3 className="text-md text-left text-custom-gray2 font-bahnschrift">
												{item.title}
											</h3>
											<div
												className={`${
													item.step !== activeStep
														? 'hidden'
														: ''
												}`}
											>
												{stepForm(item.step)}
											</div>
										</div>
									</div>
								</li>
							))}
						</ol>
					</form>

					<div className="flex flex-row justify-between items-center">
						{helpButton()}
					</div>
				</div>
			</Modal>
		</div>
	);
};
