export const Send = () => {
	return (
		<svg
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="w-6 h-[25px]"
		>
			<g id="send">
				<path
					id="Vector"
					d="M16.5 1.75L8.25 10"
					stroke="black"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_2"
					d="M16.5 1.75L11.25 16.75L8.25 10L1.5 7L16.5 1.75Z"
					stroke="black"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};
