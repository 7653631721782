export const faq = [
	{
		title: '1. Como funciona o Fantasy Sports no FUTPro?',
		content:
			'O Fantasy Sports no FUTPro combina quiz de esportes e estratégia. Os usuários se inscrevem em torneios, respondem perguntas sobre o mundo esportivo e acumulam pontos. Quem tiver a melhor pontuação leva o prêmio!',
	},
	{
		title: '2. Como os prêmios são distribuídos?',
		content:
			'O participante com a maior pontuação no torneio leva o prêmio total. As regras e valores do prêmio estão disponíveis na regra de cada torneio.',
	},
	{
		title: '3. As perguntas são difíceis?',
		content:
			'As perguntas variam de nível fácil a avançado, cobrindo temas diversos do mundo esportivo. O objetivo é desafiar seu conhecimento e estratégia para se destacar.',
	},
	{
		title: '4. Posso participar de mais de um torneio ao mesmo tempo?',
		content:
			'Sim! Você pode se inscrever em quantos torneios quiser, desde que atenda às regras de cada torneio.',
	},
	{
		title: '5. O que acontece se eu errar uma pergunta do torneio?',
		content:
			'Se você errar, não ganha os pontos daquela pergunta, mas ainda pode recuperar nas próximas perguntas. Cada ponto conta para alcançar o topo!',
	},
	{
		title: '6. Como faço para acompanhar minha pontuação no torneio?',
		content:
			'Acompanhe sua pontuação em tempo real na aba torneios. Lá você também pode ver o desempenho dos outros participantes.',
	},
	{
		title: '7. As perguntas são sempre sobre futebol?',
		content:
			'Não! Além do futebol, temos perguntas sobre outros esportes como basquete, futebol americano, e muito mais, dependendo do torneio escolhido.',
	},
	{
		title: '8. Existe limite de participantes por torneio?',
		content:
			'Alguns torneios podem ter limite de participantes, mas outros são abertos para todos. As informações específicas estão na regra de cada torneio.',
	},
];
