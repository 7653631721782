import React, {
	createContext,
	useState,
	useContext,
	useEffect,
	ReactNode,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/button';
import { CookieIcon } from 'lucide-react';
import { Capacitor } from '@capacitor/core';

interface CookieContextType {
	consent: string | null;
	handleAccept: () => void;
}

const CookiesContext = createContext<CookieContextType | undefined>(undefined);

interface CookiesProviderProps {
	children: ReactNode;
}

export const CookieProvider: React.FC<CookiesProviderProps> = ({
	children,
}) => {
	const navigate = useNavigate();
	const consent = localStorage.getItem('cookieConsent');
	const [showConsent, setShowConsent] = useState(false);

	useEffect(() => {
		if (consent !== 'true') {
			setShowConsent(true);
		}
	}, []);

	const handleAccept = () => {
		localStorage.setItem('cookieConsent', 'true');
		setShowConsent(false);
	};

	return (
		<CookiesContext.Provider value={{ consent, handleAccept }}>
			{children}

			{showConsent && !Capacitor.isNativePlatform() && (
				<div
					className={
						'fixed m-2 p-3.5 z-50 flex flex-col gap-2 justify-center items-center text-center rounded-lg border-2 border-primary bg-[#FCE8EB] lg:w-2/3 lg:flex-row lg:text-left lg:gap-3 lg:left-1/2 lg:transform lg:-translate-x-1/2 ' +
						(Capacitor.getPlatform() === 'ios'
							? 'bottom-[70px]'
							: 'bottom-[55px]')
					}
				>
					<CookieIcon className="w-12 h-12 lg:w-16 lg:h-16 text-black" />

					<div className="text-black font-bahnschrift">
						A FutPro utiliza cookies para proporcionar uma melhor
						experiência e fornecer serviços personalizados para
						você! Ao utilizar nosso site, você concorda com os
						termos descritos na{' '}
						<a
							onClick={() => {
								navigate('/privacypolicy');
							}}
							className="underline cursor-pointer"
						>
							Política de Privacidade
						</a>
					</div>
					<div className="">
						<Button
							onClick={handleAccept}
							variant="hidden"
							className={'w-full px-3 py-2'}
						>
							Ok
						</Button>
					</div>
				</div>
			)}
		</CookiesContext.Provider>
	);
};

export const useCookieContext = (): CookieContextType => {
	const context = useContext(CookiesContext);
	if (context === undefined) {
		throw new Error(
			'useCookieContext must be used within a CookieProvider'
		);
	}
	return context;
};
