import { Capacitor } from '@capacitor/core';
import { Rule as iRule } from '../../../../types/types';

interface iRules {
	data: iRule;
}

export const Rule = ({ data }: iRules) => {
	return (
		<div
			className="w-full h-full z-0 bg-[#f4f4f4] p-2 rounded-lg"
			dangerouslySetInnerHTML={{
				__html:
					Capacitor.getPlatform() === 'ios'
						? data.contentIOS
						: Capacitor.getPlatform() === 'android'
						? data.contentAndroid
						: data.content,
			}}
		/>
	);
};
