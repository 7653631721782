import React, { useEffect } from 'react';
import { useUserProfileStore, useUserStore } from '../../store/user';
import { useApi } from '../../hooks/useApi';
import { useTokenStore } from '../../store/token';
import { useAuthContext } from '../../contexts/Auth/AuthContext';

interface User {
	name: string;
	email: string;
}

interface ZendeskWidgetProps {
	user: User;
}

const ZendeskWidget: React.FC<ZendeskWidgetProps> = () => {
	const api = useApi();
	const { updateProfile } = useAuthContext();
	const token = useTokenStore((state: any) => state.token);
	const user = useUserStore((state) => state.user);
	const userData = useUserProfileStore((state: any) => state.userData);

	useEffect(() => {
		const initializeZendesk = async (userJwt: any) => {
			try {
				if (user) {
					const response = await api.supportJwt(userJwt, token);
					if (!response?.success) {
						throw new Error(response.message);
					}

					if (response.success) {
						window.zE(
							'messenger',
							'loginUser',
							(callback: Function) => {
								callback(response.token);
							}
						);
					}
				}
			} catch (error) {
				console.error('Error initializing Zendesk:', error);
			}
		};

		const logoutZendesk = () => {
			if (window.zE) {
				window.zE('messenger', 'logoutUser');
			}
		};

		if (window.zE) {
			if (userData) {
				const userJwt = {
					external_id: user?.accountId,
					name: userData?.name ? userData?.name : userData.nick,
					email: userData.email,
					email_verified: true,
					scope: 'user',
				};
				initializeZendesk(userJwt);
			} else {
				logoutZendesk();
			}
		}

		const widgetOpen = localStorage.getItem('messenger');
		if (widgetOpen === 'true') {
			window.zE('messenger', 'open');
			// window.zE('webWidget', 'open');
		}

		return () => {
			logoutZendesk();
		};
	}, [user, token]);

	useEffect(() => {
		if (user) {
			updateProfile();
		}
	}, [user]);

	return null;
};

export default ZendeskWidget;
