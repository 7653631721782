import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Layout } from '../../../../../components/layout';
import ButtonBack from '../../../../../components/ui/buttonBack';
import { LimitsAccountSchema } from '../../../../../types/responsibleGamingValidation';
import Button from '../../../../../components/ui/button';
import TextInput2 from '../../../../../components/ui/TextInput2';
import { useApi } from '../../../../../hooks/useApi';
import { useTokenStore } from '../../../../../store/token';
import { sentryCapture } from '../../../../../helpers/utils';
import { SentryError } from '../../../../../types/enums';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import Toast from '../../../../../components/ui/toast';

export const ChangeSelfLimits = () => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const [isLoading, setIsLoading] = useState(false);

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<z.infer<typeof LimitsAccountSchema>>({
		resolver: zodResolver(LimitsAccountSchema),
		mode: 'onChange',
		defaultValues: {
			registrationPerDay: '',
			registrationPerWeek: '',
			registrationPerMonth: '',
			depositPerDay: '',
			depositPerWeek: '',
			depositPerMonth: '',
		},
	});

	useEffect(() => {
		getLimits();
	}, []);

	const getLimits = async () => {
		try {
			const res = await api.getLimits(token);
			if (!res?.success) {
				throw new Error(res.message);
			}
			setValue('depositPerDay', res.data.depositPerDay || '');
			setValue('depositPerWeek', res.data.depositPerWeek || '');
			setValue('depositPerMonth', res.data.depositPerMonth || '');
			setValue('registrationPerDay', res.data.registrationPerDay || '');
			setValue('registrationPerWeek', res.data.registrationPerWeek || '');
			setValue(
				'registrationPerMonth',
				res.data.registrationPerMonth || ''
			);
		} catch (error: any) {
			sentryCapture(error, 'getLimits', SentryError.Error);
		}
	};

	const handleSave = async (data: z.infer<typeof LimitsAccountSchema>) => {
		setIsLoading(true);
		try {
			const body = {
				depositPerDay: data.depositPerDay
					? Number(data.depositPerDay)
					: null,
				depositPerWeek: data.depositPerWeek
					? Number(data.depositPerWeek)
					: null,
				depositPerMonth: data.depositPerMonth
					? Number(data.depositPerMonth)
					: null,
				registrationPerDay: data.registrationPerDay
					? Number(data.registrationPerDay)
					: null,
				registrationPerWeek: data.registrationPerWeek
					? Number(data.registrationPerWeek)
					: null,
				registrationPerMonth: data.registrationPerMonth
					? Number(data.registrationPerMonth)
					: null,
			};

			const res = await api.updateLimits(body, token);
			if (!res?.success) {
				throw new Error(res.message);
			}
			toast(<Toast variant="success">Limites atualizados!</Toast>);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Layout>
			<div className="px-3 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between py-2 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<ButtonBack classNameIcon="" />
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
						Limites Pessoais (Beta)
					</p>
					<div></div>
				</div>
				<div className="flex flex-col gap-6 lg:w-full lg:mx-auto">
					<div className="opacity-80 text-black text-base font-normal font-bahnschrift leading-tight">
						Queremos que você tenha uma experiência maravilhosa em
						nossa plataforma e acima de tudo se sinta seguro aqui!
						Os limites pessoais permitem limitar os depósitos,
						apostas e perdas por períodos específicos de tempo.
					</div>
					<form
						className="flex flex-col gap-10"
						onSubmit={handleSubmit(handleSave)}
					>
						<div className="flex flex-col gap-2">
							<div className="opacity-80 text-black text-xl font-semibold font-bahnschrift leading-7">
								Limites de Inscrição em torneio
							</div>
							<div className="flex flex-col gap-4">
								<Controller
									control={control}
									name="registrationPerDay"
									render={({
										field: { value, onChange },
									}) => (
										<TextInput2
											type="number"
											placeholder="Limite diário de inscrição em torneio (opcional)"
											value={value}
											error={
												errors?.registrationPerDay
													?.message
											}
											onChange={(val) => {
												const format = val.replace(
													/\D/g,
													''
												);
												onChange(format);
											}}
											inputMode="tel"
										/>
									)}
								/>
								<Controller
									control={control}
									name="registrationPerWeek"
									render={({
										field: { value, onChange },
									}) => (
										<TextInput2
											type="number"
											placeholder="Limite semanal de inscrição em torneio (opcional)"
											value={value}
											error={
												errors?.registrationPerWeek
													?.message
											}
											onChange={(val) => {
												const format = val.replace(
													/\D/g,
													''
												);
												onChange(format);
											}}
											inputMode="tel"
										/>
									)}
								/>
								<Controller
									control={control}
									name="registrationPerMonth"
									render={({
										field: { value, onChange },
									}) => (
										<TextInput2
											type="number"
											placeholder="Limite mensal de inscrição em torneio (opcional)"
											value={value}
											error={
												errors?.registrationPerMonth
													?.message
											}
											onChange={(val) => {
												const format = val.replace(
													/\D/g,
													''
												);
												onChange(format);
											}}
											inputMode="tel"
										/>
									)}
								/>
							</div>
						</div>
						<div className="flex flex-col gap-2">
							<div className="opacity-80 text-black text-xl font-semibold font-bahnschrift leading-7">
								Limites de Depósito
							</div>
							<div className="flex flex-col gap-4">
								<Controller
									control={control}
									name="depositPerDay"
									render={({
										field: { value, onChange },
									}) => (
										<TextInput2
											mask={'R$ num,00'}
											blocks={{
												num: {
													mask: Number,
													thousandsSeparator: '.',
													radix: ',',
													mapToRadix: ['.'],
													scale: 2,
													normalizeZeros: true,
													padFractionalZeros: true,
												},
											}}
											type="number"
											placeholder="Limite diário de depósito (opcional)"
											value={value}
											error={
												errors?.depositPerDay?.message
											}
											onChange={onChange}
											inputMode="tel"
										/>
									)}
								/>
								<Controller
									control={control}
									name="depositPerWeek"
									render={({
										field: { value, onChange },
									}) => (
										<TextInput2
											mask={'R$ num,00'}
											blocks={{
												num: {
													mask: Number,
													thousandsSeparator: '.',
													radix: ',',
													mapToRadix: ['.'],
													scale: 2,
													normalizeZeros: true,
													padFractionalZeros: true,
												},
											}}
											type="number"
											placeholder="Limite semanal de depósito (opcional)"
											value={value}
											error={
												errors?.depositPerWeek?.message
											}
											onChange={onChange}
											inputMode="tel"
										/>
									)}
								/>
								<Controller
									control={control}
									name="depositPerMonth"
									render={({
										field: { value, onChange },
									}) => (
										<TextInput2
											mask={'R$ num,00'}
											blocks={{
												num: {
													mask: Number,
													thousandsSeparator: '.',
													radix: ',',
													mapToRadix: ['.'],
													scale: 2,
													normalizeZeros: true,
													padFractionalZeros: true,
												},
											}}
											type="number"
											placeholder="Limite mensal de depósito (opcional)"
											value={value}
											error={
												errors?.depositPerMonth?.message
											}
											onChange={onChange}
											inputMode="tel"
										/>
									)}
								/>
							</div>
						</div>
						<Button
							type="submit"
							isLoading={isLoading}
							className="w-full p-3.5"
						>
							Salvar alterações
						</Button>
					</form>
				</div>
			</div>
		</Layout>
	);
};
