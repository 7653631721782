import { useCallback, useEffect, useRef, useState } from 'react';
import ModalChat from '../Modal/modalChat';
import { Send } from '../Icons/Send';
import { useUserProfileStore, useUserStore } from '../../store/user';
import { useModalLoginStore } from '../../store/modalLogin';
import {
	Channel,
	ChannelHeaderProps,
	Chat,
	EmptyStateIndicatorProps,
	MessageInput,
	useChannelStateContext,
	useCreateChatClient,
	useMessageContext,
	useMessageInputContext,
	VirtualizedMessageList,
	Window,
} from 'stream-chat-react';
import { type User, type Channel as StreamChannel } from 'stream-chat';
import { useApi } from '../../hooks/useApi';
import { Emoji } from '../Emoji';
import { ChatBubble } from '../Icons/ChatBubble';

type LiveChatProps = {
	id: string;
	tournamentId: string;
	token: string | null;
};
const apiKey = 'efe24uu7hbbc';

export const LiveChat = ({ id, tournamentId, token }: LiveChatProps) => {
	const [channel, setChannel] = useState<StreamChannel | null>(null);

	const inputRef = useRef<any>(null);
	const inputCustomRef = useRef<HTMLTextAreaElement>(null);

	const api = useApi();
	const [open, setOpen] = useState(false);
	const chatContainerRef = useRef<HTMLDivElement | null>(null);
	const userInfo = useUserProfileStore((state: any) => state.userData);
	const user = useUserStore((state) => state.user);
	const { openModalLogin } = useModalLoginStore();
	const [unreadMessages, setUnreadMessages] = useState<number>(0);

	const tokenProvider = useCallback(async () => {
		const response = await api.getTokenFeed();
		if (!response?.success) {
			throw new Error(response.message);
		}
		const { token } = response;
		return token;
	}, []);

	const client = useCreateChatClient({
		apiKey,
		tokenOrProvider: token ? token : tokenProvider,
		userData: {
			id: user ? user?.accountId : 'anonymous',
			name: user ? userInfo.nick : 'anonymous',
			image: `https://getstream.io/random_png/?name=${
				user ? userInfo.nick : 'anonymous'
			}`,
			language: 'pt',
		},
	});

	const handleNewMessage = (event: any) => {
		if (event.message.user.id !== user ? user?.accountId : 'anonymous') {
			if (!open) {
				setUnreadMessages((prev) => prev + 1);
			}
		}
	};

	useEffect(() => {
		if (open) {
			if (!client) return;

			const channel = client.channel('chatTournament', tournamentId, {
				image: 'https://getstream.io/random_png/?name=react',
				name: 'Torneio',
			});

			channel.watch();
			setChannel(channel);

			if (user) {
				channel.on('message.new', handleNewMessage);
			}

			return () => {
				channel.off('message.new', handleNewMessage);
				channel.stopWatching();
				setChannel(null);
			};
		} else {
			channel?._disconnect();
		}
	}, [open]);

	useEffect(() => {
		const chatContainer = chatContainerRef.current;
		if (chatContainer) {
			chatContainer.scrollTop = chatContainer.scrollHeight;
		}
	}, [channel?.id]);

	const handleOpenChat = () => {
		setOpen(!open);
		if (channel) {
			channel.markRead();
			setUnreadMessages(0);
		}
	};

	const CustomMessage = () => {
		const { message } = useMessageContext();

		return (
			<div className="flex flex-row justify-start items-start gap-2 py-1 px-2.5">
				<div className="text-left text-black text-sm font-semibold font-bahnschrift leading-none tracking-tight">
					{message.user?.name}
				</div>
				<div className="text-justify text-black text-sm font-light font-bahnschrift leading-none tracking-tight w-4/5">
					{message.text ===
					'Message was blocked by moderation policies'
						? 'A mensagem foi bloqueada pelas políticas de moderação!'
						: message.text}
				</div>
			</div>
		);
	};

	const CustomInput = () => {
		const { text, handleChange, handleSubmit } = useMessageInputContext();

		const handleKeyPress = (
			event: React.KeyboardEvent<HTMLTextAreaElement>
		) => {
			if (!user) {
				openModalLogin(true, '');
				return;
			}
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault();
				handleSubmit();
				if (inputCustomRef?.current) {
					inputCustomRef.current.style.height = `40px`;
					inputCustomRef?.current?.focus();
				}
			}
		};

		const autoResize = (e: any) => {
			if (inputCustomRef.current) {
				if (e.target.value === '') {
					inputCustomRef.current.style.height = `auto`;
				} else {
					inputCustomRef.current.style.height = 'auto';
					const newHeight = Math.min(
						inputCustomRef.current.scrollHeight,
						250
					);
					inputCustomRef.current.style.height = `${newHeight}px`;
				}
			}
		};

		return (
			<div className="w-full px-4 pt-2 bg-[#e3e3e3] border-t border-[#9b9b9b] flex-col justify-center items-center inline-flex">
				<div className="self-stretch grow shrink basis-0 pt-0.5 justify-center items-center gap-2.5 inline-flex">
					<div className="grow shrink basis-0 px-3 bg-white rounded-2xl border border-[#c9c4bb] justify-between items-center flex">
						<textarea
							ref={inputCustomRef}
							className="w-full text-black pt-3 text-base font-light leading-tight font-bahnschrift tracking-tight outline-none resize-none placeholder:text-left"
							placeholder="Digite aqui sua mensagem..."
							value={text}
							onChange={(e) => {
								handleChange(e);
								autoResize(e);
							}}
							onKeyDown={handleKeyPress}
							style={{
								height: '48px',
								minHeight: '48px',
								maxHeight: '250px',
								overflowY: 'auto',
							}}
						/>
						<Emoji />
					</div>

					<div
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleSubmit();
							if (inputCustomRef?.current) {
								inputCustomRef.current.style.height = `40px`;
								inputCustomRef?.current?.focus();
							}
						}}
						className="w-9 h-6 cursor-pointer"
					>
						<Send />
					</div>
				</div>

				<div className="grow shrink basis-0 px-32 py-2 flex-col justify-end items-center gap-2 flex"></div>
			</div>
		);
	};

	const CustomHeader = (props: ChannelHeaderProps) => {
		const { watcher_count } = useChannelStateContext();
		return (
			<div className="w-full h-8 px-4 py-2 bg-[#d0d0d0] justify-start items-center gap-1 inline-flex">
				<div className="flex-col justify-start items-start gap-1 inline-flex">
					<div className="text-center text-[#484848] text-sm font-bahnschrift leading-none tracking-tight">
						{watcher_count}{' '}
						{watcher_count && watcher_count > 1
							? 'participantes'
							: 'participante'}{' '}
						online
					</div>
				</div>
			</div>
		);
	};

	return (
		<div onClick={handleOpenChat} className="flex items-center gap-4">
			<div
				onClick={() => {
					setOpen(!open);
				}}
				className="w-full p-2.5 bg-neutral-300 rounded-lg border bg-[#D4D4D4] border-stone-300 flex-col justify-start items-start gap-3 flex"
			>
				<div className="self-stretch flex-row justify-between items-start gap-1 flex">
					<div className="text-center text-[#484848] text-sm font-semibold font-bahnschrift leading-tight tracking-tight">
						Chat ao vivo
					</div>

					{unreadMessages > 0 && (
						<div className="rounded-3xl px-2 py-1 bg-[#b02a37] text-white text-xs font-semibold">
							{unreadMessages}
						</div>
					)}
				</div>

				<div className="self-stretch px-2 py-1 bg-stone-300 rounded-3xl justify-start items-center gap-2.5 inline-flex">
					<div className="text-center text-neutral-700 text-xs font-bahnschrift leading-none tracking-tight">
						Clique aqui e participe do chat
					</div>
				</div>
			</div>

			<ModalChat id={id} open={open} onClose={() => setOpen(!open)}>
				{!client ? (
					<div className="w-full h-full">
						<div>Aguarde estamos conectado você ao chat...</div>
					</div>
				) : (
					<div
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
						className="w-full h-full overflow-y-auto relative"
					>
						<Chat client={client} defaultLanguage="pt">
							<Channel channel={channel!} Input={CustomInput}>
								<Window>
									<CustomHeader />
									<div
										onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}}
										className="w-full h-80"
									>
										<VirtualizedMessageList
											hideDeletedMessages
											scrollToLatestMessageOnFocus
											hideNewMessageSeparator
											disableDateSeparator
											showUnreadNotificationAlways
											Message={CustomMessage}
										/>
									</div>

									<MessageInput />
								</Window>
							</Channel>
						</Chat>
					</div>
				)}
			</ModalChat>
		</div>
	);
};
