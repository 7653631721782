import axios from 'axios';
import { getErrorMessage } from '../helpers/utils';
import io from 'socket.io-client';
import { APP_VERSION_STORE } from '../helpers/version';
import { Capacitor } from '@capacitor/core';

const baseUrl = 'https://fp.futpro.com.br';

const api = axios.create({
	baseURL: process.env.REACT_APP_API,
});

export const socket = io(baseUrl, {
	path: '/ws',
});

export const useApi = () => ({
	signIn: async (
		login: string,
		password: string,
		promocode: string | null
	) => {
		try {
			const response = await api.post('/v1/auth/login', {
				login,
				password,
				promocode,
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	signOut: async () => {
		try {
			const response = await api.post('/logout');
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validateToken: async (token: string) => {
		try {
			const response = await api.post('/validate', { token });
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	register: async (body: any) => {
		try {
			const response = await api.post('/v1/auth/register', body);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	findNotification: async (accountId: string, type: string) => {
		try {
			const response = await api.get(`/v1/notification`, {
				params: {
					accountId,
					type,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	sendTokenLogin: async (email: string) => {
		try {
			const response = await api.get(`/v1/auth/sendTokenEmail`, {
				params: {
					email,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validateTokenLogin: async (
		email: string,
		code: string,
		promocode: string | null
	) => {
		try {
			const response = await api.get(`/v1/auth/validateTokenEmail`, {
				params: {
					email,
					code,
					promocode,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validateNewEmail: async (email: string) => {
		try {
			const response = await api.get(`/v1/auth/validateNewEmail`, {
				params: {
					email,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validateNewEmailRegister: async (email: string) => {
		try {
			const response = await api.get(`/v1/auth/validateNewEmail`, {
				params: {
					email,
				},
			});
			return response.data;
		} catch (error: any) {
			const errorMessage = getErrorMessage(error);
			return error.response! ? error?.response.data : errorMessage;
		}
	},
	sendTokenForgot: async (email: string) => {
		try {
			const response = await api.get(`/v1/auth/sendTokenForgot`, {
				params: {
					email,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	sendTokenValidation: async (email: string) => {
		try {
			const response = await api.get(`/v1/auth/sendTokenValidation`, {
				params: {
					email,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validateTokenForgot: async (email: string, code: string) => {
		try {
			const response = await api.get(`/v1/auth/validateTokenForgot`, {
				params: {
					email,
					code,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validateVerificationEmailToken: async (email: string, code: string) => {
		try {
			const response = await api.get(
				`/v1/auth/validateVerificationEmailToken`,
				{
					params: {
						email,
						code,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validateNewNick: async (nick: string) => {
		try {
			const response = await api.get(`/v1/auth/validateNewNick`, {
				params: {
					nick,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	getProfile: async (token: string) => {
		try {
			const response = await api.get(`/v1/accounts/getProfile`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	updateNick: async (body: any, token: string) => {
		try {
			const response = await api.patch(`/v1/accounts/update/nick`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	updateEmail: async (body: any, token: string) => {
		try {
			const response = await api.patch(
				`/v1/accounts/update/email`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validateNewPhoneNumber: async (phone: string) => {
		try {
			const response = await api.get(`/v1/auth/validateNewPhoneNumber`, {
				params: {
					phone,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	sendTokenPhone: async (phone: string) => {
		try {
			const response = await api.get(`/v1/auth/sendTokenPhone`, {
				params: {
					phone,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validatePhoneToken: async (phone: string, code: string) => {
		try {
			const response = await api.get(`/v1/auth/validatePhoneToken`, {
				params: {
					phone,
					code,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	updatePhone: async (body: any, token: string) => {
		try {
			const response = await api.patch(
				`/v1/accounts/update/phone`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	updatePassword: async (body: any, token: string) => {
		try {
			const response = await api.patch(
				`/accounts/update/password`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	updatePixKey: async (body: any, token: string) => {
		try {
			const response = await api.patch(
				`/v1/accounts/update/pixkey`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	updateAutoWithdraw: async (body: any, token: string) => {
		try {
			const response = await api.patch(
				`/v1/accounts/update/autoWithdraw`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	uploadDocuments: async (body: any, token: string) => {
		try {
			const response = await api.post(
				`/v1/accounts/documents/create`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	cancelAccount: async (action: string, token: string) => {
		try {
			const response = await api.get(`/v1/accounts/cancelAccount`, {
				params: {
					action,
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	updatePasswordByEmail: async (body: any) => {
		try {
			const response = await api.post(
				`/accounts/updatePasswordByEmail`,
				body
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	createNewDeposit: async (method: string, body: any, token: string) => {
		try {
			const response = await api.post(
				`/v1/play/deposit/${method}`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	createNewWithdraw: async (body: any, token: string) => {
		try {
			const response = await api.post(`/v1/play/withdraw/pix`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	fetchTransactions: async (
		token: string,
		page: number,
		pageSize: number
	) => {
		try {
			const response = await api.get(`/v1/extract/all`, {
				params: {
					limit: pageSize,
					offset: page * pageSize,
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	supportJwt: async (body: string, token: string) => {
		try {
			const response = await api.post(`/v1/play/supportjwt`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validityPromocode: async (body: any, token: string) => {
		try {
			const response = await api.post(`/v1/promocode/validity`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	confirmChangedPromocode: async (body: any, token: string) => {
		try {
			const response = await api.post(`/v1/promocode/change`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	getFeed: async () => {
		try {
			const response = await api.get(`/v1/play/feed`, {});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	getTokenFeed: async () => {
		try {
			const response = await api.get(`/v1/play/feed/token`, {});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	getTournament: async (id: string, token: string) => {
		try {
			const response = await api.get(`/v1/play/tournament/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	createNewEnroll: async (body: any, token: string) => {
		try {
			const response = await api.post(`/v1/enroll/create`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	findEnroll: async (
		leagueId: string,
		tournamentId: string,
		token: string
	) => {
		try {
			const response = await api.get(`/v1/enroll/find/${leagueId}`, {
				params: {
					tournamentId,
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	getAllTournament: async (body: any, token: string) => {
		try {
			const { pageSize, offset, open, finish } = body;
			const response = await api.get(
				`/v1/play/tournament/all?limit=${pageSize}&offset=${offset}&open=${open}&finish=${finish}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	getMyTournament: async (body: any, token: string) => {
		try {
			const { pageSize, offset, open, finish } = body;
			const response = await api.get(
				`/v1/play/tournament/enroll?limit=${pageSize}&offset=${offset}&open=${open}&finish=${finish}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	getEnroll: async (id: string, token: string) => {
		try {
			const response = await api.get(`/v1/play/tournament/enroll/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	getScoreboard: async (id: string, limit: number, offset: number) => {
		try {
			const response = await api.get(`/v1/play/scoreboard/${id}`, {
				params: {
					limit,
					offset,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	generateUrl: async (body: any, token: string) => {
		try {
			const response = await api.post(
				`/v1/accounts/documents/generate/url`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	verifyPixPerTxId: async (token: string, id: string) => {
		try {
			const response = await api.get(`/v1/play/deposit/pix/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	getParticipants: async (id: string, limit: number, offset: number) => {
		try {
			const response = await api.get(`/v1/play/participants/${id}`, {
				params: {
					limit,
					offset,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	validateVersion: async () => {
		try {
			const response = await api.get('/v1/play/validateversion', {
				headers: {
					'app-version': `${APP_VERSION_STORE}:${Capacitor.getPlatform()}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	getLimits: async (token: string) => {
		try {
			const response = await api.get(`/v1/accounts/getLimits`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	updateLimits: async (body: any, token: string) => {
		try {
			const response = await api.patch(`/v1/accounts/limits`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
	temporaryAccountBlocking: async (body: any, token: string) => {
		try {
			const response = await api.patch(
				`/v1/accounts/temporaryBlock`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			throw new Error(errorMessage);
		}
	},
});
