import Footer from '../../../components/Footer';
import { Layout } from '../../../components/layout';
import ButtonBack from '../../../components/ui/buttonBack';

export const TransparencyPortal = () => {
	return (
		<Layout customBG="bg-background-dark" logoWhite>
			<div className="px-3.5 pt-7 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between px-3.5 pt-10 pb-6 lg:flex-col lg:justify-start lg:items-start lg:gap-4">
					<ButtonBack classNameIcon="text-custom-white" />
					<p className="text-xl font-semibold text-center font-bahnschrift text-custom-white lg:text-primary lg:text-left">
						Portal da Transparência
					</p>
					<div></div>
				</div>

				<div className="text-sm text-left text-custom-gray4 font-bahnschrift lg:rounded-lg lg:bg-custom-gray2 lg:p-8">
					O Canal da Transparência é o canal de denúncias do FUTPro, e
					deve ser utilizado exclusivamente para:
					<br />
					<br />
					I) Enviar relatos decorrentes de violações ao nosso Código
					de Ética e Conduta (link do nosso Código de Ética), como
					prática de corrupção, suborno, assédio moral e sexual,
					agressões físicas, entre outros; e
					<br />
					<br />
					II) Solicitações de titulares de dados pessoais também devem
					ser encaminhadas através do nosso Canal da Transparência.
					<br />
					<br />
					Se a sua dúvida e/ou relato não for relacionado a temas de
					Compliance ou Proteção de Dados Pessoais, entre em contato
					através do nosso Chat (app e web), e-mail
					(suporte@futpro.com.br) e redes sociais (Facebook, Twitter e
					Instagram).
					<br />
					<br />
				</div>
			</div>

			<Footer />
		</Layout>
	);
};
