import { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { toast } from 'sonner';
import { RiCheckLine } from '@remixicon/react';
import { ForgotPassSchema } from '../../../../types/usersValidation';
import TextInput from '../../../ui/TextInput';
import Button from '../../../ui/button';
import { useApi } from '../../../../hooks/useApi';
import { sentryCapture } from '../../../../helpers/utils';
import { SentryError } from '../../../../types/enums';
import { useAuthContext } from '../../../../contexts/Auth/AuthContext';
import Toast from '../../../ui/toast';
import { helpButton } from '../../../ui/helpButton';
import { useNavigate } from 'react-router-dom';
import { useCookieContext } from '../../../../contexts/Cookies/CookiesContext';
import PasswordCaption from '../../../PasswordCaption';

const ForgotPassword = ({ onClose }: any) => {
	const api = useApi();
	const navigate = useNavigate();
	const { handleAccept } = useCookieContext();
	const { forgotPass } = useAuthContext();
	const [code, setCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [activeStep, setActiveStep] = useState(1);
	const step2 = useRef<HTMLInputElement>(null);
	const step3 = useRef<HTMLInputElement>(null);
	const step4 = useRef<HTMLInputElement>(null);

	const [timeLeft, setTimeLeft] = useState(120);
	const [canResend, setCanResend] = useState(false);

	useEffect(() => {
		if (timeLeft > 0) {
			const timer = setInterval(() => {
				setTimeLeft(timeLeft - 1);
			}, 1000);

			return () => clearInterval(timer);
		} else {
			setCanResend(true);
		}
	}, [timeLeft]);

	const steps = [
		{ step: 1, title: 'Informe o seu e-mail' },
		{ step: 2, title: 'Informe o código de verificação' },
		{ step: 3, title: 'Informe a nova senha' },
		{ step: 4, title: 'Confirme a nova senha' },
	];

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm<z.infer<typeof ForgotPassSchema>>({
		resolver: zodResolver(ForgotPassSchema),
		mode: 'onChange',
		defaultValues: {
			email: '',
			password: '',
			confirmPassword: '',
		},
	});

	useEffect(() => {
		if (activeStep === 2 && step2.current) {
			step2.current.focus();
		}
		if (activeStep === 3 && step3.current) {
			step3.current.focus();
		}
		if (activeStep === 4 && step4.current) {
			step4.current.focus();
		}
	}, [activeStep]);

	const emailValue: any = watch('email').toLowerCase();

	const sendTokenForgot = async (step?: number) => {
		setIsLoading(true);
		try {
			const response = await api.sendTokenForgot(emailValue);
			if (!response?.success) {
				throw new Error(response.message);
			}
			setTimeLeft(120);
			setCanResend(false);
			if (step) {
				setActiveStep(step + 1);
			}
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenForgot', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const validateTokenForgot = async (step: number, code: string) => {
		if (code === '' || code.trim() === '') {
			toast(
				<Toast variant="error">Informe o token para continuar.</Toast>
			);
			return;
		}
		if (code.length < 6) {
			toast(<Toast variant="error">Informe um código válido.</Toast>);
			return;
		}
		setIsLoading(true);
		try {
			const response = await api.validateTokenForgot(emailValue, code);
			if (!response?.success) {
				throw new Error(response.message);
			}
			setActiveStep(step + 1);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'validateTokenForgot', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleForgot = async (data: z.infer<typeof ForgotPassSchema>) => {
		const body = {
			password: data.password,
			email: data.email,
			token: code,
		};
		setIsLoading(true);
		try {
			const response = await forgotPass(body);
			if (!response?.success) {
				if (
					response.message ===
					'Senha ja utilizada anteriormente, utilize uma nova.'
				) {
					setActiveStep(3);
					setValue('confirmPassword', '');
					throw new Error(response.message);
				}
				throw new Error(response.message);
			}
			onClose();
			handleAccept();
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'ForgotPassword', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const stepForm = (step: number) => {
		switch (step) {
			case 1:
				return (
					<Controller
						control={control}
						name="email"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="text"
									placeholder="E-mail"
									value={value}
									error={errors?.email?.message}
									onChange={onChange}
									classNameCustom="lowercase"
								/>
								<Button
									onClick={() => {
										sendTokenForgot(step);
									}}
									disabled={!value || !!errors?.email}
									isLoading={isLoading}
									className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
									textCustom="text-sm"
								>
									Próximo
								</Button>
							</div>
						)}
					/>
				);
			case 2:
				return (
					<div>
						<p className="text-xs text-left text-custom-gray3 pb-2 font-bahnschrift">
							<span className="text-xs text-left text-custom-gray3 font-bahnschrift">
								Enviamos um código de verificação para o e-mail{' '}
							</span>
							<span className="text-xs font-semibold text-left text-custom-gray3 font-bahnschrift">
								{emailValue}
							</span>
						</p>

						<TextInput
							type="text"
							placeholder="Digite o código"
							inputMode="tel"
							value={code}
							onChange={(e) => {
								setCode(e);
							}}
							ref={step2}
							autoFocus
						/>

						<p className="text-sm text-left font-bahnschrift">
							<span className="text-xs text-left text-custom-gray3 font-bahnschrift">
								Não recebeu o código? Verifique seu lixo
								eletrônico ou{' '}
							</span>

							{canResend ? (
								<span
									onClick={() => sendTokenForgot()}
									className="text-xs font-semibold font-bahnschrift text-left text-primary cursor-pointer"
								>
									clique aqui para reenviar
								</span>
							) : (
								<span className="text-xs font-semibold font-bahnschrift text-left text-primary cursor-pointer">
									reenvie em {Math.floor(timeLeft / 60)}:
									{('0' + (timeLeft % 60)).slice(-2)}
								</span>
							)}
						</p>

						<div className="flex gap-2">
							<Button
								variant="destructive"
								onClick={() => setActiveStep(step - 1)}
								className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
								textCustom="text-sm"
							>
								Voltar
							</Button>
							<Button
								onClick={() => validateTokenForgot(step, code)}
								isLoading={isLoading}
								className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
								textCustom="text-sm"
							>
								Próximo
							</Button>
						</div>
					</div>
				);
			case 3:
				return (
					<Controller
						control={control}
						name="password"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<div className="flex flex-col gap-3">
									<TextInput
										type="password"
										placeholder="Digite a senha"
										value={value}
										error={errors?.password?.message}
										onChange={onChange}
										ref={step3}
										autoFocus
									/>
									<PasswordCaption value={value} />
								</div>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => setActiveStep(step - 1)}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										onClick={() => setActiveStep(step + 1)}
										disabled={!value || !!errors?.password}
										isLoading={isLoading}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Próximo
									</Button>
								</div>
							</div>
						)}
					/>
				);
			case 4:
				return (
					<Controller
						control={control}
						name="confirmPassword"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="password"
									placeholder="Digite a senha"
									value={value}
									error={errors?.confirmPassword?.message}
									onChange={onChange}
									ref={step4}
									autoFocus
								/>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => setActiveStep(step - 1)}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										type="submit"
										disabled={
											!value || !!errors?.confirmPassword
										}
										isLoading={isLoading}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Confirmar
									</Button>
								</div>
							</div>
						)}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div className="overflow-y-auto lg:overflow-hidden max-h-[70vh]">
			<p className="text-2xl font-bold text-left text-custom-gray2 pb-5 font-bahnschrift">
				Recuperar senha
			</p>

			<form onSubmit={handleSubmit(handleForgot)} className="mb-7">
				<ol className="relative mx-4">
					{steps.map((item, index) => (
						<li key={index} className="relative flex flex-col">
							<div className="flex items-start relative">
								<div className="flex flex-col items-center -start-4">
									<span
										className={`absolute z-10 flex items-center justify-center font-bahnschrift w-7 h-7 rounded-full ring-custom-white ${
											item.step <= activeStep
												? 'bg-primary text-custom-white'
												: 'bg-custom-gray5 text-custom-gray2'
										}`}
									>
										{item.step < activeStep ? (
											<RiCheckLine
												size={20}
												color="#ffffff"
												className=""
											/>
										) : (
											item.step
										)}
									</span>
									<div
										className={`text-gray-500 flex border-s w-[1px] absolute ${
											item.step < activeStep
												? 'border-primary h-full -z-10'
												: activeStep === item.step &&
												  activeStep < steps.length
												? 'border-primary h-full'
												: item.step <=
														steps.length - 1 &&
												  'border-custom-gray5 h-10'
										}`}
									></div>
								</div>

								<div className="flex-1 ml-8 mb-4">
									<h3 className="text-md text-left text-custom-gray2 font-bahnschrift">
										{item.title}
									</h3>
									<div
										className={`${
											item.step !== activeStep
												? 'hidden'
												: ''
										}`}
									>
										{stepForm(item.step)}
									</div>
								</div>
							</div>
						</li>
					))}
				</ol>
			</form>

			<div className="flex flex-row justify-between items-center">
				{helpButton()}
				<div className="flex gap-3">
					<a
						onClick={() => {
							navigate('/termsofuse');
						}}
						className="text-xs text-center underline text-primary cursor-pointer font-bahnschrift"
					>
						Termos de Uso
					</a>
					<a
						onClick={() => {
							navigate('/privacypolicy');
						}}
						className="text-xs text-center underline text-primary cursor-pointer font-bahnschrift"
					>
						Política de Privacidade
					</a>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
