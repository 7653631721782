import { App } from '@capacitor/app';
import { RiCloseFill } from '@remixicon/react';
import { useEffect } from 'react';
import { toggleScroll } from '../../../helpers/utils';

type ModalProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
	title?: string;
};

const ModalDialog = ({ open, onClose, children, id, title }: ModalProps) => {
	useEffect(() => {
		toggleScroll(open);
		App.addListener('backButton', (event) => {
			if (open) {
				onClose();
			}
		});

		return () => document.body.classList.remove('overflow-hidden');
	}, [open]);

	return (
		<div
			id={id}
			onClick={onClose}
			className={`fixed inset-0 flex justify-center items-center transition-colors z-50 ${
				open ? 'visible bg-custom-gray1/70' : 'invisible'
			}`}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={`w-full max-h-[94vh] lg:max-w-[800px] lg:mx-auto mx-4 bg-custom-white rounded-xl shadow transition-all ${
					open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
				}`}
			>
				<div className="flex flex-col gap-3">
					<div className="flex flex-row justify-between items-center px-6">
						<div className="text-black text-xl font-normal font-bahnschrift">
							{title}
						</div>
						<button
							onClick={onClose}
							className="rounded-lg text-gray-400 pt-6 pb-4 bg-white hover:bg-gray-50 hover:text-gray-600 flex justify-end"
						>
							<RiCloseFill />
						</button>
					</div>

					{children}
				</div>
			</div>
		</div>
	);
};

export default ModalDialog;
