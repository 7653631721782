import OptionReplyItemView from './OptionReplyItemView';

export const ReplyOptions = ({ data }: any) => {
	return (
		<div className="flex flex-col gap-1.5">
			<div className={'transition-all duration-300 ease-in-out'}>
				<div className="flex flex-col gap-1.5">
					{data.enrollOption.map((options: any, index: number) => (
						<OptionReplyItemView
							key={index}
							item={options}
							index={options.id}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
