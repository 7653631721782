import { z } from 'zod';

export const LockAccountSchema = z.object({
	lock: z.string().min(1, 'Selecione um periodo'),
});

export const LimitsAccountSchema = z.object({
	registrationPerDay: z
		.string()
		.transform((val) => val.replace(/\D/g, ''))
		.refine((val) => val !== '', 'Selecione um número válido')
		.refine((val) => parseInt(val) >= 1, 'Selecione um número válido')
		.or(z.literal('')),
	registrationPerWeek: z
		.string()
		.transform((val) => val.replace(/\D/g, ''))
		.refine((val) => val !== '', 'Selecione um número válido')
		.refine((val) => parseInt(val) >= 1, 'Selecione um número válido')
		.or(z.literal('')),
	registrationPerMonth: z
		.string()
		.transform((val) => val.replace(/\D/g, ''))
		.refine((val) => val !== '', 'Selecione um número válido')
		.refine((val) => parseInt(val) >= 1, 'Selecione um número válido')
		.or(z.literal('')),
	depositPerDay: z.string().min(1, 'Selecione um periodo').or(z.literal('')),
	depositPerWeek: z.string().min(1, 'Selecione um periodo').or(z.literal('')),
	depositPerMonth: z
		.string()
		.min(1, 'Selecione um periodo')
		.or(z.literal('')),
});
