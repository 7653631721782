export const Dump = () => {
	return (
		<svg
			width="21"
			height="25"
			viewBox="0 0 21 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="h-8 relative text-primary"
		>
			<g id="Img">
				<path
					id="Vector"
					d="M7.69687 2.35156L6.97969 3.5H14.0203L13.3031 2.35156C13.1672 2.13125 12.9234 2 12.6656 2H8.32969C8.07187 2 7.83281 2.13125 7.69219 2.35156H7.69687ZM14.5781 1.55938L15.7922 3.5H18H19.5H20.25C20.6625 3.5 21 3.8375 21 4.25C21 4.6625 20.6625 5 20.25 5H19.5V20.75C19.5 22.8219 17.8219 24.5 15.75 24.5H5.25C3.17812 24.5 1.5 22.8219 1.5 20.75V5H0.75C0.3375 5 0 4.6625 0 4.25C0 3.8375 0.3375 3.5 0.75 3.5H1.5H3H5.20781L6.42188 1.55938C6.83437 0.898438 7.55625 0.5 8.32969 0.5H12.6656C13.4438 0.5 14.1609 0.898438 14.5734 1.55938H14.5781ZM3 5V20.75C3 21.9922 4.00781 23 5.25 23H15.75C16.9922 23 18 21.9922 18 20.75V5H3ZM6.97031 10.4703C7.26094 10.1797 7.73906 10.1797 8.02969 10.4703L10.5 12.9406L12.9703 10.4703C13.2609 10.1797 13.7391 10.1797 14.0297 10.4703C14.3203 10.7609 14.3203 11.2391 14.0297 11.5297L11.5594 14L14.0297 16.4703C14.3203 16.7609 14.3203 17.2391 14.0297 17.5297C13.7391 17.8203 13.2609 17.8203 12.9703 17.5297L10.5 15.0594L8.02969 17.5297C7.73906 17.8203 7.26094 17.8203 6.97031 17.5297C6.67969 17.2391 6.67969 16.7609 6.97031 16.4703L9.44062 14L6.97031 11.5297C6.67969 11.2391 6.67969 10.7609 6.97031 10.4703Z"
					fill="#DF1737"
				/>
			</g>
		</svg>
	);
};
