import { useEffect, useState } from 'react';
import { TableLeague } from '../../../../components/TableLeague';
import TabsButtonLeague from '../../../../components/TabsButtonLeague';
import { useApi } from '../../../../hooks/useApi';
import { sentryCapture } from '../../../../helpers/utils';
import { SentryError } from '../../../../types/enums';
import { RiRestartLine } from '@remixicon/react';
import { useUserStore } from '../../../../store/user';

interface iParticipants {
	tournamentStatus: number;
	tournamentId: string | undefined;
	leagues: any[];
	myEnrolls: any[];
}

const thead = [
	{
		title: 'Inscrição',
	},
	{
		title: 'Liga',
	},
	{
		title: 'Posição',
	},
	{
		title: 'Pontos',
	},
	{
		title: 'Prêmio',
	},
];

export const Participants = ({
	tournamentStatus,
	tournamentId,
	leagues,
	myEnrolls,
}: iParticipants) => {
	const api = useApi();
	const user = useUserStore((state) => state.user);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [participants, setParticipants] = useState<any>([]);

	const limit = page === 0 ? 20 : 100;
	const offset = page === 0 ? 0 : 20 + (page - 1) * 100;

	useEffect(() => {
		fetchParticipants();
	}, []);

	const fetchParticipants = async () => {
		if (!tournamentId) return;
		try {
			setLoading(true);

			const res = await api.getParticipants(tournamentId, limit, offset);
			if (!res?.success) {
				throw new Error(res.message);
			}

			setPage((prevPage) => prevPage + 1);
			setParticipants((prevFeed: any) => [
				...prevFeed,
				...res.data.participants,
			]);

			if (res.currentPage >= res.totalPages) {
				setHasMore(false);
				return;
			}
		} catch (error: any) {
			sentryCapture(error, 'fetchParticipants', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	const handleLoadMore = async () => {
		if (!tournamentId) return;
		try {
			setIsLoading(true);

			const res = await api.getParticipants(tournamentId, limit, offset);
			if (!res?.success) {
				throw new Error(res.message);
			}

			setParticipants((prevFeed: any) => [
				...prevFeed,
				...res.data.participants,
			]);
			setPage((prevPage) => prevPage + 1);
			if (res.currentPage >= res.totalPages) {
				setHasMore(false);
				return;
			}
		} catch (error: any) {
			sentryCapture(error, 'fetchParticipants', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	if (loading) {
		return (
			<div className="flex items-center justify-center">
				<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
			</div>
		);
	}

	const data = participants.map((item: any) => {
		const leagueHasWinPoints = participants.some(
			(other: any) => other.league === item.league && other.winPoints > 0
		);
		return {
			...item,
			leagueHasWinPoints,
		};
	});

	const participantsAll = data.sort((a: any, b: any) => {
		return a.classification - b.classification;
	});
	const participantsPerLeague = (item: any) => {
		return data
			.filter((league: any) => league.league === item.league.title)
			.sort((a: any, b: any) => {
				return a.classification - b.classification;
			});
	};

	const leagueArray = leagues?.map((item: any, index: number) => ({
		title: item.league.title,
		renderPage: () => (
			<TableLeague
				key={index}
				thead={thead}
				tbody={participantsPerLeague(item)}
				tournamentStatus={tournamentStatus}
			/>
		),
	}));

	const myRegistrations = [
		{
			title: 'Minhas',
			renderPage: () => (
				<TableLeague
					thead={thead}
					tbody={myEnrolls}
					tournamentStatus={tournamentStatus}
				/>
			),
		},
	];

	const showMyEnrolls = myEnrolls.length > 0 ? myRegistrations : [];

	const render =
		leagues.length > 1
			? [
					...showMyEnrolls,
					{
						title: 'Todas',
						renderPage: () => (
							<TableLeague
								thead={thead}
								tbody={participantsAll}
								tournamentStatus={tournamentStatus}
							/>
						),
					},
					...leagueArray,
			  ]
			: [...showMyEnrolls, ...leagueArray];

	return (
		<div className="flex flex-col gap-2">
			<TabsButtonLeague
				initialPage={
					myEnrolls.length
						? 'Minhas'
						: leagues.length > 1
						? 'Todas'
						: render[0].title
				}
				render={render}
				hasMore={hasMore}
				isLoading={isLoading}
				onClickButtonHasMore={handleLoadMore}
			/>
		</div>
	);
};
