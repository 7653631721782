import { useNavigate } from 'react-router-dom';
import { CurrencyFormatter, showWalletAndBalance } from '../../helpers/utils';
import { useEffect, useState } from 'react';
import { FutProIcon } from '../Icons/FutProIcon';

interface TableScoreboardFeedProps {
	thead: any[];
	tbody: any[];
	tournamentStatus?: any;
}

export const TableScoreboardFeed = ({
	thead,
	tbody,
	tournamentStatus,
}: TableScoreboardFeedProps) => {
	const navigate = useNavigate();
	const [previousTbody, setPreviousTbody] = useState(tbody);

	useEffect(() => {
		setPreviousTbody(tbody);
	}, [tbody]);

	return (
		<table className="min-w-full table-auto border-collapse bg-white border border-stone-300/30">
			<thead className="border-b border-[#bcc0c4]">
				<tr>
					{thead.map((item, index) => (
						<th
							key={index}
							className="py-2 max-w-48 text-center self-stretch text-[#73777c] text-[10px] font-medium font-bahnschrift leading-3 tracking-tight"
						>
							{item.title}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{tbody.map((item, index) => {
					const prevIndex = previousTbody.findIndex(
						(prevItem) => prevItem.nick === item.nick
					);
					const positionChange = prevIndex - index;

					return (
						<tr
							key={index}
							onClick={(e) => {
								if (item.enrollLeagueId) {
									return navigate(
										`/enroll/${item.enrollLeagueId}`
									);
								}
							}}
							className={`h-10 transition-transform ${
								Number(item.award) > 0
									? ''
									: 'even:bg-[#f5f5f6]'
							} ${
								positionChange > 0 && Number(item.award) > 0
									? 'animate-move-up-blink'
									: positionChange < 0 &&
									  Number(item.award) > 0
									? 'animate-move-up-blink'
									: Number(item.award) > 0
									? 'animate-blink'
									: ''
							}`}
						>
							{Number(item.classification) > 0 ? (
								<td className="px-2 py-1">
									<div className="py-1 bg-primary  rounded-full justify-center items-center">
										<div className="text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
											{item.classification}
										</div>
									</div>
								</td>
							) : (
								<td></td>
							)}

							{Number(item.winPoints) > 0 ? (
								<td className="px-2 py-1">
									<div className="py-1 bg-[#73777c] rounded-full justify-center items-center">
										<div className="text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
											{item.winPoints}
										</div>
									</div>
								</td>
							) : (
								<td className="px-2 py-1">
									{tournamentStatus !== 0 ? (
										<div className="py-1 bg-[#73777c] rounded-full justify-center items-center">
											<div className="text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
												0
											</div>
										</div>
									) : (
										<></>
									)}
								</td>
							)}
							<td className="px-2 py-1 text-center self-stretch text-black text-[12px] font-medium font-bahnschrift leading-[10px]">
								{item.nick.toLowerCase()}
							</td>

							{Number(item.award) > 0 ? (
								<td className="px-2 py-1 mb-0.5 flex items-center">
									<div className="px-2 py-0.5 flex-col items-center inline-flex flex-1">
										<span className="text-black text-xs max-[359px]:text-[9px] font-medium font-bahnschrift leading-3">
											{tournamentStatus === 3
												? 'Garantiu'
												: 'Quase garantido'}
										</span>
										<span className="text-black text-sm max-[359px]:text-xs font-bold font-bahnschrift leading-3 flex items-center gap-1">
											{showWalletAndBalance() ? (
												'R$ '
											) : (
												<FutProIcon
													style="w-3.5 h-3.5"
													color="#000000"
												/>
											)}
											{CurrencyFormatter(
												Number(item.award)
											)}
										</span>
									</div>
								</td>
							) : (
								<td></td>
							)}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};
