import { App } from '@capacitor/app';
import { useEffect } from 'react';
import { toggleScroll } from '../../../helpers/utils';

type ModalProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
};

const PlayersSelect = ({ open, onClose, children, id }: ModalProps) => {
	useEffect(() => {
		toggleScroll(open);

		App.addListener('backButton', (event) => {
			if (open) {
				onClose();
			}
		});

		return () => document.body.classList.remove('overflow-hidden');
	}, [open]);

	return (
		<div
			id={id}
			className={`fixed inset-0 flex justify-center items-center transition-colors z-50 ${
				open
					? 'visible bg-custom-black lg:bg-custom-gray1/70'
					: 'invisible'
			}`}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={`w-full h-[95vh] lg:h-auto lg:max-w-screen-xl lg:bg-custom-black lg:rounded-xl lg:p-6 lg:mx-auto shadow transition-all ${
					open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
				}`}
			>
				{children}
			</div>
		</div>
	);
};

export default PlayersSelect;
