import { useState, useRef, useEffect, memo, useCallback } from 'react';
import Button from '../ui/button';

interface TabsProps {
	initialPage: string;
	render: {
		title: string;
		renderPage: () => JSX.Element;
	}[];
	hasMore: boolean;
	isLoading: boolean;
	onClickButtonHasMore: () => Promise<void>;
}

const TabsButtonLeague = memo(
	({
		render,
		initialPage,
		hasMore,
		isLoading,
		onClickButtonHasMore,
	}: TabsProps) => {
		const [openTab, setOpenTab] = useState(initialPage);
		const tabListRef = useRef<HTMLUListElement>(null);
		const activeTabRef = useRef<HTMLLIElement>(null);

		useEffect(() => {
			if (activeTabRef.current && tabListRef.current) {
				const activeTab = activeTabRef.current;
				const tabList = tabListRef.current;
				const activeTabRect = activeTab.getBoundingClientRect();
				const tabListRect = tabList.getBoundingClientRect();

				if (activeTabRect.left < tabListRect.left) {
					tabList.scrollLeft -= tabListRect.left - activeTabRect.left;
				} else if (activeTabRect.right > tabListRect.right) {
					tabList.scrollLeft +=
						activeTabRect.right - tabListRect.right;
				}
			}
		}, [openTab]);

		const handleMouseDown = useCallback((e: React.MouseEvent) => {
			if (!tabListRef.current) return;
			const tabList = tabListRef.current;
			const startX = e.pageX - tabList.offsetLeft;
			const scrollLeft = tabList.scrollLeft;

			const onMouseMove = (e: MouseEvent) => {
				const x = e.pageX - tabList.offsetLeft;
				const walk = (x - startX) * 2;
				tabList.scrollLeft = scrollLeft - walk;
			};

			const onMouseUp = () => {
				document.removeEventListener('mousemove', onMouseMove);
				document.removeEventListener('mouseup', onMouseUp);
				document.body.style.userSelect = '';
			};

			document.addEventListener('mousemove', onMouseMove);
			document.addEventListener('mouseup', onMouseUp);
			document.body.style.userSelect = 'none';
		}, []);

		return (
			<>
				<div className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto">
					<ul
						className="grid grid-flow-row grid-cols-4 border-solid border-b border-[#979797] gap-0.5 whitespace-nowrap scrollbar-hide"
						role="tablist5"
						ref={tabListRef}
						onMouseDown={handleMouseDown}
					>
						{render.map((tabsTitle) => (
							<li
								key={tabsTitle.title}
								className={
									'flex flex-shrink-0 cursor-pointer select-none font-bahnschrift rounded-t transition-all duration-700 transform ' +
									(openTab === tabsTitle.title
										? 'bg-white translate-x-0'
										: ' bg-[#F5F5F5]')
								}
								ref={
									openTab === tabsTitle.title
										? activeTabRef
										: null
								}
							>
								<a
									className={
										'text-sm max-[359px]:text-[9px] py-1 w-full text-center font-bahnschrift ' +
										(openTab === tabsTitle.title
											? 'text-[#767676]'
											: ' text-[#979797] ')
									}
									onClick={(e) => {
										e.preventDefault();
										setOpenTab(tabsTitle.title);
									}}
									data-toggle="tab"
									href={`#${tabsTitle.title}`}
									role="tablist5"
									aria-selected={openTab === tabsTitle.title}
								>
									{tabsTitle.title}
								</a>
							</li>
						))}
					</ul>

					<div className="relative flex flex-col min-w-0 break-words w-full">
						<div className="flex-auto">
							{render.map((tabsRender) => (
								<div
									className="tab-content tab-space"
									key={tabsRender.title}
								>
									<div
										className={
											openTab === tabsRender.title
												? ''
												: 'hidden'
										}
										id={tabsRender.title}
									>
										{tabsRender.renderPage()}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				{hasMore && openTab !== 'Minhas' && (
					<Button
						isLoading={isLoading}
						onClick={onClickButtonHasMore}
						className="w-full px-3.5 py-3.5 mb-6"
					>
						Carregar mais
					</Button>
				)}
			</>
		);
	}
);

export default TabsButtonLeague;
