import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { LockAccountSchema } from '../../../../../types/responsibleGamingValidation';
import { Layout } from '../../../../../components/layout';
import Button from '../../../../../components/ui/button';
import ButtonBack from '../../../../../components/ui/buttonBack';
import SelectInput from '../../../../../components/ui/selectInput';
import { useState } from 'react';
import { sentryCapture } from '../../../../../helpers/utils';
import { SentryError } from '../../../../../types/enums';
import { toast } from 'sonner';
import Toast from '../../../../../components/ui/toast';
import { useTokenStore } from '../../../../../store/token';
import { useApi } from '../../../../../hooks/useApi';
import { useAuthContext } from '../../../../../contexts/Auth/AuthContext';

export const LockAccount = () => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const { signOut } = useAuthContext();
	const [isLoading, setIsLoading] = useState(false);
	const options = [
		{ value: '31', label: 'Um dia' },
		{ value: '32', label: 'Uma semana' },
		{ value: '33', label: 'Um mês' },
		{ value: '34', label: 'Três meses' },
	];

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<z.infer<typeof LockAccountSchema>>({
		resolver: zodResolver(LockAccountSchema),
		mode: 'onChange',
		defaultValues: {
			lock: '',
		},
	});

	const handleSave = async (data: z.infer<typeof LockAccountSchema>) => {
		setIsLoading(true);
		try {
			const body = {
				lock: data.lock,
			};

			const res = await api.temporaryAccountBlocking(body, token);
			if (!res?.success) {
				throw new Error(res.message);
			}
			toast(
				<Toast variant="success">
					Conta bloqueada temporariamente!
				</Toast>
			);
			signOut();
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Layout>
			<div className="px-3 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between py-2 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<ButtonBack classNameIcon="" />
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
						Bloqueio temporário de conta (Beta)
					</p>
					<div></div>
				</div>
				<div className="flex flex-col gap-6 lg:w-full lg:mx-auto">
					<div className="opacity-80 text-black text-base font-normal font-bahnschrift leading-tight">
						Se você não se sentir mais à vontade com o seu
						comportamento, você pode bloquear sua conta
						temporariamente. Em caso de bloqueio temporário, não é
						necessário efetuar a retirada do saldo de sua conta
						antes de concluir o pedido.
					</div>

					<form
						className="flex flex-col gap-2"
						onSubmit={handleSubmit(handleSave)}
					>
						<Controller
							control={control}
							name="lock"
							render={({ field: { value, onChange } }) => (
								<SelectInput
									options={options}
									placeholder="Por quanto tempo deseja bloquear sua conta?"
									value={value}
									onChange={onChange}
									error={errors.lock?.message}
									autoFocus
								/>
							)}
						/>
						<Button
							type="submit"
							isLoading={isLoading}
							className="w-full p-3.5"
						>
							Bloquear temporariamente
						</Button>
					</form>
				</div>
			</div>
		</Layout>
	);
};
