import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as LiveUpdates from '@capacitor/live-updates';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Toaster } from 'sonner';
import Navigation from './Router/routes';
import { sentryCapture } from './helpers/utils';
import { SentryError } from './types/enums';
import { AuthProvider } from './contexts/Auth/AuthProvider';
import { StreamProvider } from './contexts/GetStream/StreamContext';
import '@ionic/react/css/core.css';
import { setupIonicReact } from '@ionic/react';
import OneSignal from 'onesignal-cordova-plugin';
import OneSignalWeb from 'react-onesignal';
import AppUrlListener from './components/AppUrlListener';
import { PromoProvider } from './contexts/PromoCode/PromoContext';
import { EnrollProvider } from './contexts/Enroll/EnrollContext';
import { CookieProvider } from './contexts/Cookies/CookiesContext';
import { Filesystem } from '@capacitor/filesystem';
import { Camera } from '@capacitor/camera';
import { UpdateVersionProvider } from './contexts/UpdateVersion/UpdateVersion';

setupIonicReact();

export default function App() {
	const platform = Capacitor.getPlatform();

	const runOneSignalWeb = async () => {
		OneSignalWeb.Debug.setLogLevel('6');
		await OneSignalWeb.init({
			appId: '8354282b-7378-4252-b706-c96020faf35f',
		});
	};

	const initializeOneSignal = async () => {
		if (Capacitor.isNativePlatform()) {
			OneSignal.Debug.setLogLevel(6);
			OneSignal.initialize('8354282b-7378-4252-b706-c96020faf35f');
			OneSignal.Notifications.addEventListener('click', async (e) => {
				let clickData = await e.notification;
				console.log('Notification Clicked : ' + clickData);
			});

			const permission =
				await OneSignal.Notifications.getPermissionAsync();
			console.log('Current Notification Permission: ', permission);

			if (!permission) {
				try {
					const success =
						await OneSignal.Notifications.requestPermission(true);
					console.log('Permissão concedida: ', success);
				} catch (err) {
					console.error('Erro ao solicitar permissão: ', err);
				}
			} else {
				console.log('Permissão já concedida.');
			}
		} else {
			await runOneSignalWeb();
		}
	};

	const initializeApp = async () => {
		if (Capacitor.isNativePlatform()) {
			try {
				const result = await LiveUpdates.sync();
				if (result.activeApplicationPathChanged) {
					await LiveUpdates.reload();
				} else {
					await SplashScreen.hide();
				}
			} catch {
				sentryCapture(
					'Error Live Update',
					'Live-Updates',
					SentryError.Error
				);
			}
			if (platform === 'android') {
				document.documentElement.classList.add('android');
			} else if (platform === 'ios') {
				document.documentElement.classList.add('ios');
			}
			SplashScreen.hide();
		} else {
			console.log('Live Update not working.');
		}
	};
	const initializePermission = async () => {
		if (Capacitor.isNativePlatform()) {
			const permissions = await Filesystem.checkPermissions();
			if (permissions.publicStorage === 'granted') {
				console.log('Permissão já concedida.');
			} else if (permissions.publicStorage === 'denied') {
				console.log('Permissão negada.');
			} else {
				const request = await Filesystem.requestPermissions();

				if (request.publicStorage === 'granted') {
					console.log('Permissão concedida após solicitação.');
				} else {
					console.log('Permissão não concedida.');
				}
			}

			const permissionCamera = await Camera.checkPermissions();
			if (permissionCamera.camera === 'granted') {
				console.log('Permissão já concedida.');
			} else if (permissionCamera.camera === 'denied') {
				console.log('Permissão negada.');
			} else {
				const requestCamera = await Camera.requestPermissions();

				if (requestCamera.camera === 'granted') {
					console.log('Permissão concedida após solicitação.');
				} else {
					console.log('Permissão não concedida.');
				}
			}
		}
	};

	useEffect(() => {
		initializeOneSignal();
		initializePermission();
		initializeApp();
	}, []);

	return (
		<BrowserRouter>
			<AppUrlListener />
			<Toaster
				richColors
				position="top-center"
				toastOptions={{ duration: 3000, unstyled: true }}
				className="mt-11"
				visibleToasts={1}
				expand
			/>
			<CookieProvider>
				<UpdateVersionProvider>
					<PromoProvider>
						<AuthProvider>
							<StreamProvider>
								<EnrollProvider>
									<Navigation />
								</EnrollProvider>
							</StreamProvider>
						</AuthProvider>
					</PromoProvider>
				</UpdateVersionProvider>
			</CookieProvider>
		</BrowserRouter>
	);
}
