export const PadLock2 = () => {
	return (
		<svg
			width="21"
			height="25"
			viewBox="0 0 21 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="h-8 relative text-primary"
		>
			<g id="Img">
				<path
					id="Vector"
					d="M6 6.5V9.5H15V6.5C15 4.01562 12.9844 2 10.5 2C8.01562 2 6 4.01562 6 6.5ZM4.5 9.5V6.5C4.5 3.18594 7.18594 0.5 10.5 0.5C13.8141 0.5 16.5 3.18594 16.5 6.5V9.5H17.25C19.3219 9.5 21 11.1781 21 13.25V20.75C21 22.8219 19.3219 24.5 17.25 24.5H3.75C1.67812 24.5 0 22.8219 0 20.75V13.25C0 11.1781 1.67812 9.5 3.75 9.5H4.5ZM1.5 13.25V20.75C1.5 21.9922 2.50781 23 3.75 23H17.25C18.4922 23 19.5 21.9922 19.5 20.75V13.25C19.5 12.0078 18.4922 11 17.25 11H3.75C2.50781 11 1.5 12.0078 1.5 13.25Z"
					fill="#DF1737"
				/>
			</g>
		</svg>
	);
};
