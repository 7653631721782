import { useEffect, useState } from 'react';
import { countRealTime } from '../../helpers/utils';
import { Clock } from '../Icons/Clock';

export const StopWatch = ({ data }: any) => {
	const [remainingTime, setRemainingTime] = useState(
		countRealTime(data.entryEventAt)
	);

	useEffect(() => {
		if (data) {
			const interval = setInterval(() => {
				const time = countRealTime(data?.entryEventAt);
				setRemainingTime(time);
				if (time.isFinished) {
					clearInterval(interval);
				}
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [data?.entryEventAt]);

	if (data?.tournamentStatus !== 0) {
		return <div></div>;
	}

	return (
		<div className="flex flex-row gap-1 items-center justify-center">
			<Clock color="#a2a5ab" />
			<span className="text-sm text-center text-[#a2a5ab] font-bahnschrift">
				Fecha em:
			</span>
			<span className="text-sm text-center text-[#a2a5ab] font-bahnschrift">
				{remainingTime &&
					`${remainingTime.days}d : ` +
						`${remainingTime.hours
							.toString()
							.padStart(2, '0')}h : ` +
						`${remainingTime.minutes
							.toString()
							.padStart(2, '0')}m : ` +
						`${remainingTime.seconds.toString().padStart(2, '0')}s`}
			</span>
		</div>
	);
};
