import { App } from '@capacitor/app';
import { useEffect } from 'react';
import { toggleScroll } from '../../helpers/utils';

type ModalProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
};

const ModalInvite = ({ open, onClose, children, id }: ModalProps) => {
	useEffect(() => {
		toggleScroll(open);
		App.addListener('backButton', (event) => {
			if (open) {
				onClose();
			}
		});

		return () => document.body.classList.remove('overflow-hidden');
	}, [open]);

	return (
		<div
			id={id}
			onClick={onClose}
			className={`fixed inset-0 flex justify-center items-center transition-colors z-50 ${
				open ? 'visible bg-custom-gray1/70' : 'invisible'
			}`}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={`w-full lg:max-w-96 lg:mx-auto mx-4 bg-invite bg-top rounded-xl shadow p-6 transition-all ${
					open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
				}`}
			>
				{children}
			</div>
		</div>
	);
};

export default ModalInvite;
