import { useEffect, useState } from 'react';
import {
	countRealTime,
	CurrencyFormatter,
	showWalletAndBalance,
} from '../../helpers/utils';
import {
	ChannelTypes,
	ClickActionType,
	ContentTypes,
	TournamentTypeDisplay,
} from '../../types/enums';
import { FeedData } from '../../types/types';
import { useNavigate } from 'react-router-dom';
import Button from '../ui/button';
import { useFeedUpdateStore } from '../../store/feedUpdate';
import { Clock } from '../Icons/Clock';
import { useModalDialogFeedStore } from '../../store/modalDialogFeed';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';
import { TableScoreboardFeed } from '../TableScoreboardFeed';
import TabsButtonScoreBoard from '../TabsButtonScoreBoard';
import { FutProIcon } from '../Icons/FutProIcon';

interface IProps {
	item: FeedData;
	index: any;
}

const FeedItem = ({ item, index }: IProps) => {
	const navigate = useNavigate();
	const { modalDialogFeed, openModal } = useModalDialogFeedStore();
	const { updateFeed } = useFeedUpdateStore();
	const [remainingTime, setRemainingTime] = useState(
		countRealTime(item.finishAt)
	);

	useEffect(() => {
		const interval = setInterval(() => {
			const time = countRealTime(item.finishAt);
			setRemainingTime(time);
			if (time.isFinished) {
				clearInterval(interval);
				updateFeed(true);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const handleNavigator = () => {
		if (item.content.contentType === ContentTypes.SCOREBOARD) {
			return navigate(`/scoreboard/${item.id}`);
		}
		if (item.content.contentType === ContentTypes.TOURNAMENT) {
			return navigate(`/tournaments/${item.tournamentId}`);
		} else {
			if (item.content.clickAction === ClickActionType.NOTHING) {
				return;
			}
			if (item.content.clickAction === ClickActionType.DIALOG) {
				openModal(!modalDialogFeed, item);
			}
			if (item.content.clickAction === ClickActionType.NAVIGATE) {
				window.open(item.content.urlAction, '_blank');
			}
		}
	};

	const renderFeed = (type: number) => {
		switch (type) {
			case 0:
				return (
					<button
						onClick={handleNavigator}
						className="relative w-full h-60 lg:h-80 overflow-hidden rounded-md mb-2.5"
						style={{
							boxShadow: '0px 4px 4px 0 rgba(0,0,0,0.25)',
						}}
					>
						{item.content.image && (
							<picture>
								<source
									srcSet={item.content.image}
									media="(max-width: 767px)"
									className="absolute inset-0 lg:h-80 w-full h-full object-cover z-0"
								/>
								<source
									srcSet={item.content.image}
									media="(max-width: 768px)"
									className="absolute inset-0 lg:h-80 w-full h-full object-cover z-0"
								/>
								<source
									srcSet={item.content.imageWeb}
									media="(max-width: 1024px)"
									className="absolute inset-0 lg:h-80 w-full h-full object-cover z-0"
								/>
								<img
									className="absolute inset-0 lg:h-80 w-full h-full object-cover z-0"
									alt={item.content.title}
									src={item.content.imageWeb}
								/>
							</picture>
						)}

						<div
							className={`absolute inset-0 w-full h-full flex flex-col justify-between z-10 ${
								item.channel === ChannelTypes.HOMEHEAD &&
								'hidden'
							}`}
						>
							{item.content.html ? (
								<div
									dangerouslySetInnerHTML={{
										__html: item.content.html,
									}}
								/>
							) : (
								<div
									className={`absolute inset-0 w-full h-full flex flex-col justify-end z-10 ${
										item.channel ===
											ChannelTypes.HOMEHEAD && 'hidden'
									}`}
								>
									<div
										className="px-4"
										style={{
											background:
												'linear-gradient(0.06deg, #1d1f24 0.05%, rgba(61,61,61,0.71) 170.43%)',
										}}
									>
										<div className="bg-[#262626] w-[150px] m rounded-full p-1 -mt-[13px] font-bahnschrift text-custom-white text-sm">
											{
												TournamentTypeDisplay[
													item.tournamentStatus
												]
											}
										</div>
										<div className="font-bahnschrift text-custom-white text-sm text-left">
											<div className="grid grid-row-2">
												<div className="flex flex-row text-center justify-between items-center">
													<span className="text-xl font-bahnschrift w-1/2 flex justify-center items-center gap-1">
														{showWalletAndBalance() ? (
															'R$ '
														) : (
															<FutProIcon
																style="w-3.5 h-3.5 mr-1"
																color="#ffffff"
															/>
														)}
														{CurrencyFormatter(
															Number(item.award)
														)}
													</span>

													<span className="font-bahnschrift pb-1.5 text-center w-1/2">
														{item.content.title}
													</span>
												</div>

												<div className="flex flex-row text-center justify-between items-center">
													<span className="font-bahnschrift w-1/2">
														Em prêmios
													</span>
													<Button
														className="py-1 w-1/2"
														textCustom="text-base"
													>
														Participar
													</Button>
												</div>
											</div>
											<div className="px-1 z-20 mx-auto p-1.5 rounded-tr rounded-tl bg-custom-gray9/75 backdrop-blur-[7px]">
												<div className="flex flex-row gap-1 items-center justify-center">
													<Clock color="#D1D1D1" />
													<span className="text-sm text-center text-custom-gray8 font-bahnschrift">
														Fecha em:
													</span>
													<span className="text-sm text-center text-custom-white font-bahnschrift">
														{`${remainingTime.days}d : ` +
															`${remainingTime.hours
																.toString()
																.padStart(
																	2,
																	'0'
																)}h : ` +
															`${remainingTime.minutes
																.toString()
																.padStart(
																	2,
																	'0'
																)}m : ` +
															`${remainingTime.seconds
																.toString()
																.padStart(
																	2,
																	'0'
																)}s`}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</button>
				);
			case 1:
				return (
					<button
						onClick={handleNavigator}
						className="relative w-full h-60 lg:h-80 overflow-hidden rounded-xl bg-custom-gray10"
					>
						{item.content.image ? (
							<picture>
								<source
									srcSet={item.content.image}
									media="(max-width: 767px)"
									className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
										item.channel === ChannelTypes.HOMEHEAD
											? 'h-60 lg:h-80'
											: 'h-[200px] lg:h-[280px]'
									}`}
								/>
								<source
									srcSet={item.content.image}
									media="(max-width: 768px)"
									className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
										item.channel === ChannelTypes.HOMEHEAD
											? 'h-60 lg:h-80'
											: 'h-[200px] lg:h-[280px]'
									}`}
								/>
								<source
									srcSet={item.content.imageWeb}
									media="(max-width: 1024px)"
									className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
										item.channel === ChannelTypes.HOMEHEAD
											? 'h-60 lg:h-80'
											: 'h-[200px] lg:h-[280px]'
									}`}
								/>
								<img
									className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
										item.channel === ChannelTypes.HOMEHEAD
											? 'h-60 lg:h-80'
											: 'h-[200px] lg:h-[280px]'
									}`}
									alt={item.content.title}
									src={item.content.imageWeb}
								/>
							</picture>
						) : (
							<div
								className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
									item.channel === ChannelTypes.HOMEHEAD
										? 'h-60 lg:h-80'
										: 'h-[200px] lg:h-[280px]'
								}`}
								dangerouslySetInnerHTML={{
									__html: item.content.html,
								}}
							/>
						)}

						<div
							className={`absolute inset-0 w-full h-full flex flex-col justify-between z-10 ${
								item.channel === ChannelTypes.HOMEHEAD &&
								'hidden'
							}`}
						>
							<div className="bg-[#262626] w-[120px] rounded-full p-2.5 m-3.5 font-bahnschrift text-custom-white text-sm">
								Publicidade
							</div>

							<div className="font-bahnschrift text-custom-white text-sm mb-3">
								Saiba mais
							</div>
						</div>
					</button>
				);
			case 2:
				return (
					<button
						onClick={handleNavigator}
						className="relative w-full h-60 lg:h-80 overflow-hidden rounded-xl bg-custom-gray10"
					>
						{item.content.image ? (
							<picture>
								<source
									srcSet={item.content.image}
									media="(max-width: 767px)"
									className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
										item.channel === ChannelTypes.HOMEHEAD
											? 'h-60 lg:h-80'
											: 'h-[200px] lg:h-[280px]'
									}`}
								/>
								<source
									srcSet={item.content.image}
									media="(max-width: 768px)"
									className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
										item.channel === ChannelTypes.HOMEHEAD
											? 'h-60 lg:h-80'
											: 'h-[200px] lg:h-[280px]'
									}`}
								/>
								<source
									srcSet={item.content.imageWeb}
									media="(max-width: 1024px)"
									className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
										item.channel === ChannelTypes.HOMEHEAD
											? 'h-60 lg:h-80'
											: 'h-[200px] lg:h-[280px]'
									}`}
								/>
								<img
									className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
										item.channel === ChannelTypes.HOMEHEAD
											? 'h-60 lg:h-80'
											: 'h-[200px] lg:h-[280px]'
									}`}
									alt={item.content.title}
									src={item.content.imageWeb}
								/>
							</picture>
						) : (
							<div
								className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
									item.channel === ChannelTypes.HOMEHEAD
										? 'h-60 lg:h-80'
										: 'h-[200px] lg:h-[280px]'
								}`}
								dangerouslySetInnerHTML={{
									__html: item.content.html,
								}}
							/>
						)}

						<div
							className={`absolute inset-0 w-full h-full flex flex-col justify-end z-10 ${
								item.channel === ChannelTypes.HOMEHEAD &&
								'hidden'
							}`}
						>
							<div
								className="px-4"
								style={{
									background:
										'linear-gradient(0.06deg, #1d1f24 0.05%, rgba(61,61,61,0.71) 170.43%)',
								}}
							>
								<div className="bg-[#262626] w-[180px] m rounded-full p-2.5 -mt-[18px] font-bahnschrift text-custom-white text-sm">
									{format(
										item.content.created_at,
										'dd/MM/yyyy - HH:mm',
										{
											locale: ptBR,
										}
									)}
								</div>
								<div className="font-bahnschrift text-custom-white text-sm text-left pt-2 pb-5">
									{item.content.title}
								</div>
							</div>
						</div>
					</button>
				);
			case 3:
				return (
					<button
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleNavigator();
						}}
						className="relative w-full h-72 lg:h-80 overflow-hidden rounded-xl bg-custom-gray10"
					>
						{item.content.image ? (
							<img
								className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
									item.channel === ChannelTypes.HOMEHEAD
										? 'h-72 lg:h-80'
										: 'h-[200px] lg:h-[280px]'
								}`}
								alt={item.content.title}
								src={item.content.image}
							/>
						) : (
							<div
								className={`absolute inset-0 w-full object-cover px-4 z-0 rounded-b-xl ${
									item.channel === ChannelTypes.HOMEHEAD
										? 'h-72 lg:h-80'
										: 'h-[200px] lg:h-[280px]'
								}`}
								dangerouslySetInnerHTML={{
									__html: item.content.html,
								}}
							/>
						)}

						{item.content.image && (
							<div className="absolute inset-0 w-full h-full flex flex-col items-center mt-3 z-10">
								<div className="relative -mb-2 bg-primary rounded-3xl justify-center items-center gap-2.5 inline-flex">
									<div className="py-1 px-6 text-center text-white text-base font-bold font-bahnschrift leading-snug">
										{item.content.tournament?.title}
									</div>
								</div>
								<div className="w-full px-1 py-1.5 bg-neutral-70/10 rounded-lg justify-center items-center inline-flex">
									<div className="flex flex-col">
										<span className="text-white text-center font-bahnschrift">
											Confira a classificação deste
											torneio.
										</span>
										<span className="text-white text-center font-bahnschrift">
											{item.content.tournament?.reference}
										</span>
									</div>
								</div>
							</div>
						)}

						<div
							className={`absolute inset-0 w-full h-full flex flex-col justify-end z-20 ${
								item.channel === ChannelTypes.HOMEHEAD &&
								'hidden'
							}`}
						>
							<div className="font-bahnschrift text-custom-white text-sm text-left px-4">
								<TabsButtonScoreBoard
									initialPage={
										item.leaguesTournament[0].league.title
									}
									render={item.leaguesTournament?.map(
										(league: any, index: number) => ({
											title: league.league.title,
											renderPage: () => (
												<TableScoreboardFeed
													thead={[
														{
															title: 'Posição',
														},
														{
															title: 'Pontos',
														},
														{
															title: 'Nick',
														},
														{
															title: '',
														},
													]}
													tbody={item.scoreboard
														.filter(
															(
																leagueScore: any
															) =>
																leagueScore.league ===
																league.league
																	.title
														)
														.sort(
															(a, b) =>
																Number(
																	a.position
																) -
																Number(
																	b.position
																)
														)}
													tournamentStatus={
														item.tournamentStatus
													}
												/>
											),
										})
									)}
								/>
							</div>
						</div>
					</button>
				);
			default:
				return <></>;
		}
	};

	return (
		<div
			key={index}
			className="w-full rounded-md px-3 gap-2 flex flex-col items-start lg:max-w-screen-xl "
		>
			{renderFeed(item.content.contentType)}
		</div>
	);
};

export default FeedItem;
