import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type NavigationStore = {
	navigateState: any;
	saveNavigate: (store: any) => void;
	resetNavigate: () => void;

	cameFromBackButton: boolean;
	goBackButton: (cameFromBackButton: boolean) => void;
};

export const useNavigateStore = create(
	persist<NavigationStore>(
		(set) => ({
			navigateState: null,
			cameFromBackButton: false,
			goBackButton: (cameFromBackButton) => set({ cameFromBackButton }),
			saveNavigate: (navigateState) => set({ navigateState }),
			resetNavigate: () => set({ navigateState: null }),
		}),
		{
			name: 'navigateStore',
			storage: createJSONStorage(() => localStorage),
		}
	)
);
