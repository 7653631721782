export const Balance = () => {
	return (
		<svg
			width="30"
			height="24"
			viewBox="0 0 30 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="h-8 relative text-primary"
		>
			<g id="Img">
				<path
					id="Vector"
					d="M18 3C18 4.39687 17.0438 5.57344 15.75 5.90625V22.5H24.75C25.1625 22.5 25.5 22.8375 25.5 23.25C25.5 23.6625 25.1625 24 24.75 24H15H5.25C4.8375 24 4.5 23.6625 4.5 23.25C4.5 22.8375 4.8375 22.5 5.25 22.5H14.25V5.90625C12.9563 5.57344 12 4.39687 12 3H5.25C4.8375 3 4.5 2.6625 4.5 2.25C4.5 1.8375 4.8375 1.5 5.25 1.5H12.4031C12.9188 0.604687 13.8891 0 15 0C16.1109 0 17.0812 0.604687 17.5969 1.5H24C24.4125 1.5 24.75 1.8375 24.75 2.25C24.75 2.6625 24.4125 3 24 3H18ZM20.6578 16.9828C21.4594 17.5828 22.6359 18 24 18C25.3641 18 26.5406 17.5828 27.3422 16.9828C28.1437 16.3828 28.5 15.6656 28.5 15H19.5V14.925V14.9297V15C19.5 15.6656 19.8563 16.3828 20.6578 16.9828ZM24 6.90469L19.9828 13.5H28.0172L24 6.90469ZM18 15V14.925C18 14.2359 18.1875 13.5609 18.5484 12.975L22.8609 5.8875C23.1047 5.48906 23.5359 5.24531 24 5.24531C24.4641 5.24531 24.9 5.48906 25.1391 5.8875L29.4516 12.975C29.8078 13.5609 30 14.2359 30 14.925V15C30 17.4844 27.3141 19.5 24 19.5C20.6859 19.5 18 17.4844 18 15ZM1.5 15C1.5 15.6656 1.85625 16.3828 2.65781 16.9828C3.45938 17.5828 4.63594 18 6 18C7.36406 18 8.54063 17.5828 9.34219 16.9828C10.1438 16.3828 10.5 15.6656 10.5 15H1.5V14.925V14.9297V15ZM1.98281 13.5H10.0172L6 6.90469L1.98281 13.5ZM6 19.5C2.68594 19.5 0 17.4844 0 15V14.925C0 14.2359 0.1875 13.5609 0.548437 12.975L4.86094 5.8875C5.10469 5.48906 5.53594 5.24531 6 5.24531C6.46406 5.24531 6.9 5.48906 7.13906 5.8875L11.4516 12.975C11.8078 13.5609 12 14.2359 12 14.925V15C12 17.4844 9.31406 19.5 6 19.5ZM15 4.5C15.3978 4.5 15.7794 4.34196 16.0607 4.06066C16.342 3.77936 16.5 3.39782 16.5 3C16.5 2.60218 16.342 2.22064 16.0607 1.93934C15.7794 1.65804 15.3978 1.5 15 1.5C14.6022 1.5 14.2206 1.65804 13.9393 1.93934C13.658 2.22064 13.5 2.60218 13.5 3C13.5 3.39782 13.658 3.77936 13.9393 4.06066C14.2206 4.34196 14.6022 4.5 15 4.5Z"
					fill="#DF1737"
				/>
			</g>
		</svg>
	);
};
