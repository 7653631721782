import { useLocation, useNavigate } from 'react-router-dom';
import { nav } from '../data/nav';
import { useAuthContext } from '../contexts/Auth/AuthContext';
import { useModalLoginStore } from '../store/modalLogin';
import { useNotificationStore } from '../store/notification';

import { Notification } from './Icons/Notification';
import { Rss } from './Icons/Rss';
import { Tournaments } from './Icons/Tournaments';
import { Profile } from './Icons/Profile';
import { Capacitor } from '@capacitor/core';

export const NavBar = () => {
	const { user } = useAuthContext();
	const notificationCount = useNotificationStore(
		(state) => state.notification
	);
	const { openModalLogin } = useModalLoginStore();
	const location = useLocation();
	const navigate = useNavigate();
	const { pathname } = location;
	const isNotLogged = !user;

	const RenderIcon = (icon: string) => {
		switch (icon) {
			case 'Rss':
				return <Rss />;
			case 'Tournaments':
				return <Tournaments />;
			case 'Notification':
				return <Notification />;
			case 'Profile':
				return <Profile />;
			default:
				null;
		}
	};

	return (
		<div
			className={
				'btm-nav bg-primary fixed bottom-0 left-0 z-40 w-full ' +
				(Capacitor.getPlatform() === 'ios' ? 'h-[70px]' : 'h-[55px]')
			}
		>
			<div className="flex flex-row h-full justify-between">
				{nav.map((item, index) => (
					<a
						onClick={() => {
							if (
								isNotLogged &&
								item.name !== 'Feed' &&
								item.name !== 'Torneios'
							) {
								openModalLogin(true, '');
							}
							navigate(
								isNotLogged &&
									item.name !== 'Feed' &&
									item.name !== 'Torneios'
									? '#.'
									: item.href
							);
						}}
						key={index}
						className={
							'flex h-full w-full justify-items-center items-center justify-center font-bahnschrift ' +
							(pathname === item.href
								? 'bg-nav-active text-custom-white border-primary '
								: ' ') +
							(Capacitor.getPlatform() === 'ios' ? 'pb-2.5' : '')
						}
					>
						<button
							className={`inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50`}
						>
							{!isNotLogged &&
								item.toggle &&
								notificationCount > 0 && (
									<span className="absolute inline-flex items-center font-bahnschrift justify-center px-1.5 h-6 text-xs font-bold bg-error border-2 text-custom-white rounded-full top-1 ml-[12%]">
										{notificationCount}
									</span>
								)}

							{RenderIcon(item.icon)}

							<span
								className={
									pathname === item.href
										? 'active text-xs btm-nav-label text-center text-custom-white font-bahnschrift'
										: 'text-xs text-gray-500 group-hover:text-blue-600 text-custom-white font-bahnschrift'
								}
							>
								{item.name}
							</span>
						</button>
					</a>
				))}
			</div>
		</div>
	);
};
