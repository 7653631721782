import { toast } from 'sonner';
import { Layout } from '../../../../../components/layout';
import Button from '../../../../../components/ui/button';
import ButtonBack from '../../../../../components/ui/buttonBack';
import Toast from '../../../../../components/ui/toast';
import { sentryCapture } from '../../../../../helpers/utils';
import { SentryError } from '../../../../../types/enums';
import { useApi } from '../../../../../hooks/useApi';
import { useAuthContext } from '../../../../../contexts/Auth/AuthContext';
import { useTokenStore } from '../../../../../store/token';
import { useBalanceStore } from '../../../../../store/balance';
import { useState } from 'react';
import Modal from '../../../../../components/Modal/modal';
import { Warning } from '../../../../../components/Icons/Warning';

export const CloseAccount = () => {
	const api = useApi();
	const { signOut } = useAuthContext();
	const token = useTokenStore((state: any) => state.token);
	const currentBalance = useBalanceStore((state) => state.balance);

	const [isLoading, setIsLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [typeClosure, setTypeClosure] = useState('');

	const handleCancelAccount = async (action: string) => {
		if (action === '') {
			return;
		}
		if (parseFloat(currentBalance) > 0) {
			toast(
				<Toast variant="error">
					Antes de {action === 'inactive' ? 'inativar' : 'encerrar'} a
					sua conta solicite o saque do seu saldo!
				</Toast>
			);
			return;
		}
		setIsLoading(true);
		try {
			const response = await api.cancelAccount(action, token);
			if (!response?.success) {
				throw new Error(response.message);
			}
			toast(
				<Toast variant="success">
					Conta {action === 'inactive' ? 'inativada' : 'encerrada'}{' '}
					com sucesso!
				</Toast>
			);
			signOut();
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleAccountClosure = (type: string) => {
		setModal(!modal);
		setTypeClosure(type);
	};

	return (
		<Layout>
			<div className="px-3 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between py-2 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<ButtonBack classNameIcon="" />
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
						Fechar conta definitivamente (Beta)
					</p>
					<div></div>
				</div>
				<div className="flex flex-col gap-6 lg:w-full lg:mx-auto">
					<div className="opacity-80 text-black text-base font-normal font-bahnschrift leading-tight">
						Se você não se sentir mais à vontade com o seu
						comportamento, você pode fechar sua conta
						permanentemente.
						<br />
						<br />
						Observe que você deve primeiro retirar integralmente o
						saldo de sua conta e a seguir solicitar o pedido de
						fechamento.
					</div>

					<Button
						className="w-full p-3.5"
						onClick={() => handleAccountClosure('closed')}
						isLoading={isLoading}
					>
						Fechar conta permanentemente
					</Button>
				</div>
			</div>
			<Modal
				id="accountClosure"
				open={modal}
				onClose={() => setModal(!modal)}
			>
				<div className="flex flex-col gap-6 items-center">
					<Warning />

					<p className="text-2xl font-bold text-center w-96 text-custom-gray2 font-bahnschrift">
						Atenção
					</p>
					<p className="text-sm text-center text-custom-gray2 font-bahnschrift">
						{typeClosure === 'inactive'
							? 'Deseja realmente inativar sua conta?'
							: 'Deseja realmente excluir sua conta?'}
					</p>
					<p className="text-sm text-center text-custom-gray2 font-bahnschrift">
						{typeClosure === 'inactive'
							? 'Seus dados permanecerão guardados por mais 30 dias. Após esse período, não será mais possível recuperar a sua conta.'
							: 'Sua conta será excluída definitivamente, e não será possível recuperar os seus dados.'}
					</p>
					<div className="w-full pt-2 flex flex-row gap-2">
						<Button
							onClick={() => {
								setModal(!modal);
							}}
							variant="destructive"
							className={'w-full p-3'}
							isLoading={isLoading}
						>
							Não
						</Button>
						<Button
							onClick={() => {
								handleCancelAccount(typeClosure);
							}}
							isLoading={isLoading}
							className={'w-full p-3'}
						>
							Sim
						</Button>
					</div>
				</div>
			</Modal>
		</Layout>
	);
};
