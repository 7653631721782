import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { useApi } from '../hooks/useApi';
import { sentryCapture } from '../helpers/utils';
import { SentryError } from '../types/enums';
import { useUpdateVersionContext } from '../contexts/UpdateVersion/UpdateVersion';
import { useAuthContext } from '../contexts/Auth/AuthContext';

// PUBLIC
import { Feed } from '../app/Feed';
import { Help } from '../app/Help';
import { TermsOfUse } from '../app/public/TermsOfUse';
import { PrivacyPolicy } from '../app/public/PrivacyPolicy';
import { AboutUs } from '../app/public/AboutUs';
import { FeedLogged } from '../app/private/FeedLogged';

// PRIVATE
import { Notification } from '../app/private/Notification';
import { Tournaments } from '../app/private/Tournaments';
import { Profile } from '../app/private/Profile';
import { MyProfile } from '../app/private/MyProfile';
import { Deposit } from '../app/private/Wallet/Deposit';
import { Withdraw } from '../app/private/Wallet/Withdraw';
import { Wallet } from '../app/private/Wallet';
import { Error404 } from '../app/404';
import { Rules } from '../app/public/Rules';
import { TransparencyPortal } from '../app/public/TransparencyPortal';
import { TournamentById } from '../app/private/TournamentById';
import { EnrollById } from '../app/private/EnrollById';
import { LegalAge } from '../app/public/LegalAge';
import { ScoreboardById } from '../app/public/Scoreboard';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { ResponsibleGaming } from '../app/public/ResponsibleGaming';
import { CloseAccount } from '../app/private/MyProfile/ResponsibleGaming/CloseAccount';
import { LockAccount } from '../app/private/MyProfile/ResponsibleGaming/LockAccount';
import { ChangeSelfLimits } from '../app/private/MyProfile/ResponsibleGaming/ChangeSelfLimits';

export default function Navigation() {
	const { user } = useAuthContext();
	const { showModalUpdate } = useUpdateVersionContext();
	const api = useApi();

	const validateVersionCheck = async () => {
		if (Capacitor.isNativePlatform()) {
			const updateLater = localStorage.getItem('updateLater');
			const updateData = updateLater && JSON.parse(updateLater);

			try {
				const check = await api.validateVersion();

				if (!check.success) {
					if (
						updateLater &&
						updateData.bypass &&
						!check.isUpdateMandatory
					) {
						const now = new Date();
						const savedDate = new Date(updateData.date);
						const diffInMilliseconds =
							now.getTime() - savedDate.getTime();
						const diffInHours =
							diffInMilliseconds / (1000 * 60 * 60);

						if (diffInHours >= 12) {
							showModalUpdate(check);
						}
						return;
					}

					if (!check.isUpdateMandatory) {
						showModalUpdate(check);
						return;
					}
					if (check.isUpdateMandatory) {
						showModalUpdate(check);
						return;
					}
				}
			} catch {
				sentryCapture(
					'Validate Version Check',
					'checkversion',
					SentryError.Error
				);
			}
		}
	};

	useEffect(() => {
		validateVersionCheck();
	}, []);

	return (
		<Routes>
			{/* PUBLICS */}
			{!user && <Route path="/" element={<Feed />} />}
			<Route path="/help" element={<Help />} />
			<Route path="/about-us" element={<AboutUs />} />
			<Route path="/termsOfLegalAge" element={<LegalAge />} />
			<Route path="/termsofuse" element={<TermsOfUse />} />
			<Route path="/privacypolicy" element={<PrivacyPolicy />} />
			<Route path="/rules" element={<Rules />} />
			<Route path="/transparency" element={<TransparencyPortal />} />
			<Route path="scoreboard/:id" element={<ScoreboardById />} />
			<Route path="tournaments" element={<Tournaments />} />
			<Route path="tournaments/:id" element={<TournamentById />} />
			<Route path="enroll/:id" element={<EnrollById />} />
			<Route path="/jogo-responsavel" element={<ResponsibleGaming />} />
			<Route path="*" element={<Error404 />} />
			{/* PRIVADAS */}
			<Route path="/" element={user ? <FeedLogged /> : <Feed />} />
			<Route
				path="/notifications"
				element={<ProtectedRoute element={<Notification />} />}
			/>
			<Route
				path="/profile"
				element={<ProtectedRoute element={<Profile />} />}
			/>
			<Route
				path="/my-profile"
				element={<ProtectedRoute element={<MyProfile />} />}
			/>
			<Route
				path="/my-profile/change-selflimits"
				element={<ProtectedRoute element={<ChangeSelfLimits />} />}
			/>
			<Route
				path="/my-profile/lock-account"
				element={<ProtectedRoute element={<LockAccount />} />}
			/>
			<Route
				path="/my-profile/close-account"
				element={<ProtectedRoute element={<CloseAccount />} />}
			/>
			<Route
				path="/wallet"
				element={<ProtectedRoute element={<Wallet />} />}
			/>
			<Route
				path="/wallet/deposit"
				element={<ProtectedRoute element={<Deposit />} />}
			/>
			<Route
				path="/wallet/withdraw"
				element={<ProtectedRoute element={<Withdraw />} />}
			/>
		</Routes>
	);
}
