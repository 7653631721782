import { useState, useRef, useEffect, memo, useCallback } from 'react';
import { useEnrollContext } from '../../contexts/Enroll/EnrollContext';

interface TabsProps {
	initialPage: string;
	render: {
		title: string;
		renderPage: () => JSX.Element;
	}[];
}

const TabsInLine = memo(({ render, initialPage }: TabsProps) => {
	const [openTab, setOpenTab] = useState(initialPage);
	const tabListRef = useRef<HTMLUListElement>(null);
	const activeTabRef = useRef<HTMLLIElement>(null);
	const { SetTabs } = useEnrollContext();

	useEffect(() => {
		if (activeTabRef.current && tabListRef.current) {
			const activeTab = activeTabRef.current;
			const tabList = tabListRef.current;
			const activeTabRect = activeTab.getBoundingClientRect();
			const tabListRect = tabList.getBoundingClientRect();

			if (activeTabRect.left < tabListRect.left) {
				tabList.scrollLeft -= tabListRect.left - activeTabRect.left;
			} else if (activeTabRect.right > tabListRect.right) {
				tabList.scrollLeft += activeTabRect.right - tabListRect.right;
			}
		}
	}, [openTab]);

	useEffect(() => {
		const pattern = /^\/tournaments\/[a-fA-F0-9-]{36}$/;
		if (pattern.test(location.pathname)) {
			SetTabs(openTab);
		}
	}, [location.pathname, openTab]);

	const handleMouseDown = useCallback((e: React.MouseEvent) => {
		if (!tabListRef.current) return;
		const tabList = tabListRef.current;
		const startX = e.pageX - tabList.offsetLeft;
		const scrollLeft = tabList.scrollLeft;

		const onMouseMove = (e: MouseEvent) => {
			const x = e.pageX - tabList.offsetLeft;
			const walk = (x - startX) * 2;
			tabList.scrollLeft = scrollLeft - walk;
		};

		const onMouseUp = () => {
			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
			document.body.style.userSelect = '';
		};

		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);
		document.body.style.userSelect = 'none';
	}, []);

	return (
		<div className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto gap-2 mb-2">
			<ul
				className="flex list-none flex-row overflow-x-auto whitespace-nowrap scrollbar-hide"
				role="tablist6"
				ref={tabListRef}
				onMouseDown={handleMouseDown}
			>
				{render.map((tabsTitle, index) => (
					<li
						key={tabsTitle.title}
						className={
							'flex gap-2 p-2 flex-shrink-0 cursor-pointer select-none font-bahnschrift transition-all duration-700 transform ' +
							(openTab === tabsTitle.title
								? 'border-solid border-b border-primary translate-x-0'
								: 'border-solid border-b border-[#979797]')
						}
						ref={openTab === tabsTitle.title ? activeTabRef : null}
					>
						<a
							className={
								'text-sm px-3 text-left font-bahnschrift ' +
								(openTab === tabsTitle.title
									? 'text-primary'
									: 'text-[#979797]')
							}
							onClick={(e) => {
								e.preventDefault();
								setOpenTab(tabsTitle.title);
							}}
							data-toggle="tab"
							href={`#${tabsTitle.title}`}
							role="tablist6"
							aria-selected={openTab === tabsTitle.title}
						>
							{tabsTitle.title}
						</a>
					</li>
				))}
			</ul>

			<div className="relative flex flex-col min-w-0 break-words w-full">
				<div className="flex-auto">
					{render.map((tabsRender, index) => (
						<div
							className="tab-content tab-space"
							key={tabsRender.title}
						>
							<div
								className={
									openTab === tabsRender.title ? '' : 'hidden'
								}
								id={tabsRender.title}
							>
								{tabsRender.renderPage()}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
});

export default TabsInLine;
