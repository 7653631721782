import Footer from '../../../components/Footer';
import { Layout } from '../../../components/layout';
import ButtonBack from '../../../components/ui/buttonBack';

export const TermsOfUse = () => {
	return (
		<Layout customBG="bg-background-dark" logoWhite>
			<div className="px-3.5 pt-7 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between px-3.5 pt-10 pb-6 lg:flex-col lg:justify-start lg:items-start lg:gap-4">
					<ButtonBack classNameIcon="text-custom-white" />
					<p className="text-xl font-semibold text-center font-bahnschrift text-custom-white lg:text-primary lg:text-left">
						Termos de Uso
					</p>
					<div></div>
				</div>

				<div className="text-sm text-left text-custom-gray4 font-bahnschrift lg:rounded-lg lg:bg-custom-gray2 lg:p-8">
					Conheça os Termos de Uso do FUTPRO e divirta-se de forma
					consciente!
					<br />
					<br />
					Caro Usuário, seja bem-vindo ao FUTPRO!
					<br />
					<br />
					Estes Termos de Uso (“Termos”) são um acordo vinculante
					entre Você (“Você”, ou “Usuário”) e a FUTPro.
					<br />
					<br />
					A utilização de todos os produtos, serviços e
					funcionalidades das Plataformas (“Produtos do FUTPRO”)
					implica o acesso e tratamento de determinadas informações e
					dados pessoais dos Usuários para determinadas finalidades,
					conforme previsto na Política de Privacidade, a qual explica
					como o FUTPRO coleta e utiliza os dados pessoais dos
					Usuários.
					<br />
					<br />
					Os presentes Termos explicam quais são as regras de
					utilização dos Produtos do FUTPRO oferecidos aos
					usuários.Para utilizar os Produtos do FUTPRO, o Usuário
					garante que (i) tem 18 (dezoito) anos ou mais, (ii) está em
					pleno gozo de sua capacidade civil, (iii) possui poderes
					para cumprir com as obrigações previstas nestes Termos e nas
					demais políticas aplicáveis, e (iv) não se enquadra em
					nenhuma hipótese de impedimento legal para a utilização dos
					Produtos do FUTPRO.
					<br />
					<br />
					1. Aplicabilidade, Aceitação e Alteração dos Termos e
					políticas aplicáveis
					<br />
					<br />
					1.1 A utilização e o acesso do Usuário aos Produtos do
					FUTPRO estão integralmente sujeitos aos presentes Termos, à
					Política de Privacidade, à Política de Jogo Responsável, à
					Política de Prevenção e Combate à Lavagem de Dinheiro, e
					demais políticas aplicáveis e disponíveis em nossas
					plataformas.
					<br />
					<br />
					1.2 Ao acessar, se cadastrar ou utilizar os Produtos do
					FUTPRO, o Usuário manifesta concordância integral, expressa,
					voluntária, irrevogável e irretratável com estes Termos e
					demais políticas aplicáveis, incluindo, mas não se
					limitando, à Política de Privacidade, à Política de Jogo
					Responsável, à Política de Prevenção e Combate à Lavagem de
					Dinheiro, e às demais políticas listadas no item 1.1, acima,
					declarando estar ciente de todo o seu conteúdo, tendo lido,
					entendido e aceitado todas as regras, condições e obrigações
					estabelecidas nesses documentos.
					<br />
					<br />
					1.3 O FUTPRO poderá, a seu exclusivo critério, revisar ou
					alterar as cláusulas destes Termos e demais políticas
					aplicáveis, visando ao aprimoramento, à melhoria dos
					serviços ofertados, ou à incrementação de novas funções e
					recursos para assegurar a operacionalidade ou a segurança da
					experiência do Usuário nos Produtos do FUTPRO.
					<br />
					<br />
					1.4 Qualquer nova versão dos Termos e demais políticas
					aplicáveis entrará em vigor a partir do momento em que for
					publicada. O Usuário será informado com antecedência acerca
					da nova versão desses documentos, sendo que a continuidade
					de utilização dos Produtos do FUTPRO implica a aceitação,
					por parte do Usuário, das novas disposições.
					<br />
					<br />
					1.5 Caso o Usuário não queira utilizar o FUTPRO sob a nova
					versão dos Termos e demais políticas aplicáveis, deverá
					encerrar imediatamente sua conta entrando em contato com o
					FUTPRO, por meio do e-mail suporte@futpro.com.br, desde que
					não reste nenhuma obrigação pendente de cumprimento.
					<br />
					<br />
					2. Cadastro e verificação da identidade do Usuário
					<br />
					<br />
					2.1 O cadastro e a criação de uma conta são condições
					essenciais para que o Usuário usufrua dos Produtos do
					FUTPRO.
					<br />
					<br />
					2.2 Para se cadastrar, o Usuário deverá estar,
					necessariamente, em território brasileiro, uma vez que os
					Produtos do FUTPRO não são disponibilizados em outros
					países.
					<br />
					<br />
					2.3 O Usuário deverá ter o máximo de atenção quando de seu
					cadastro, ficando certo, desde já, que o FUTPRO não se
					responsabiliza por erros do Usuário no seu cadastro, como,
					por exemplo, cadastro duplo, informação errada de dados e/ou
					informações oriundas de cadastros por meio de redes sociais
					e/ou aplicações de internet.
					<br />
					<br />
					2.4 A confidencialidade do Login e da Senha, bem como
					quaisquer danos potencialmente resultantes da não manutenção
					do sigilo dessas informações serão de exclusiva
					responsabilidade do Usuário. O FUTPRO não será responsável
					por qualquer dano ou perda decorrente da conta do Usuário ou
					da omissão do mesmo em cumprir estes requisitos. O Usuário
					será responsabilizado pelas perdas do FUTPRO ou de terceiros
					oriundas do uso de sua conta por terceiros.
					<br />
					<br />
					2.5 O Usuário não poderá escolher como Login palavras,
					expressões ou conjuntos gráfico-denominativos que já tenham
					sido escolhidos por outro Usuário. O Usuário reconhece ser
					vedada a escolha de expressões malsoantes, injuriosas,
					coincidentes com marcas de produtos ou serviços,
					denominações sociais, expressões publicitárias, nomes ou
					pseudônimos de personalidades, de pessoas famosas ou outras
					expressões registradas por terceiros e/ou que não remetam à
					identidade dos usuários e/ou que sejam contrários à lei, à
					ordem pública ou às exigências da moral e dos bons costumes.
					Além disso, é terminantemente proibido o uso de nomes de
					marcas, programas, produtos e/ou serviços de propriedade
					e/ou oferecidos pelo FUTPRO e/ou de qualquer uma de suas
					sociedades direta ou indiretamente controladas,
					controladoras ou sob controle comum.
					<br />
					<br />
					2.6 No ato do cadastro, será facultado ao FUTPRO oferecer ao
					Usuário que desejar a opção de associar suas contas com os
					seus perfis em redes sociais ou outras aplicações de
					internet (tais como, mas sem se limitar a, Facebook e/ou
					Google) e, através disso, inserir uma imagem/foto de
					identificação, que será utilizada para compor seu perfil no
					FUTPRO. O FUTPRO se reserva o direito de, após inserida
					imagem/foto, examiná-la e, no caso de julgá-la imprópria,
					por razões baseadas em leis e/ou quaisquer normas, regras,
					condições ou políticas do FUTPRO, retirá-las. O Usuário
					desde já autoriza que a foto que compõe o seu perfil seja
					(i) visível para outros usuários e (ii) usada pelo FUTPRO
					para identificá-lo. Além disso, o Usuário autoriza o FUTPRO
					a utilizar a foto/imagem que compõe o seu perfil em qualquer
					contexto comercial relacionado com o objeto do presente
					termo, inclusive em programas de televisão, publicidades em
					qualquer meio de comunicação, entre outros, desde que
					lícitos.
					<br />
					<br />
					2.7 Após 180 (cento e oitenta dias) de conta inativa, o
					FUTPRO pode encerrar a conta do Usuário.
					<br />
					<br />
					3. Obrigações e Responsabilidades do Usuário
					<br />
					<br />
					3.1 O Usuário reconhece e aceita que, ao usar os Produtos do
					FUTPRO, pode ganhar e perder dinheiro. O Usuário reconhece e
					declara ter plena ciência quanto aos riscos de dependência e
					de transtornos do jogo patológico.
					<br />
					<br />
					3.2 O Usuário assume integralmente todos os ônus e
					responsabilidades decorrentes de seus atos nos Produtos do
					FUTPRO, respondendo, ainda, pelos atos praticados por
					terceiros que eventualmente se utilizem de Login e Senha de
					sua titularidade. Qualquer atividade na conta do Usuário é
					considerada de sua responsabilidade se seu Login e Senha
					tiverem sido usados com sucesso para ingresso na plataforma
					do FUTPRO.
					<br />
					<br />
					3.3 O Usuário que, enquanto em atendimento com os canais do
					FUTPRO, ofender, discriminar, assediar ou tomar qualquer
					conduta potencialmente criminosa contra os atendentes da
					equipe do FUTPRO poderá responder, inclusive criminalmente,
					pelos seus atos, além de ser prontamente banido da
					plataforma.
					<br />
					<br />
					3.4 O Usuário cede seus direitos de imagem e nome para fins
					de divulgação, publicidade e marketing nas campanhas do
					FUTPRO, em quaisquer plataformas, sejam elas digitais ou
					não.
					<br />
					<br />
					3.5 O Usuário reconhece e concorda que, para fins de
					utilização dos Produtos do FUTPRO, somente será permitida a
					criação de uma conta por CPF. O Usuário também compreende
					que qualquer tentativa de criação de múltiplas contas para
					um mesmo CPF estará sujeita a sanções por parte do FUTPRO,
					inclusive o banimento de todas as contas do Usuário. Se o
					FUTPRO, a qualquer tempo, confirmar que um Usuário manteve,
					criou, usou ou controlou mais de uma conta, todas as contas
					mantidas por esse Usuário poderão ser encerradas ou
					suspensas, e toda premiação ganha pode ser cancelada ou
					revogada.
					<br />
					<br />
					3.6 O Usuário deverá indenizar o FUTPRO ou terceiros por
					quaisquer ações ou omissões que causem prejuízos advindos do
					descumprimento das regras estabelecidas nestes Termos, nas
					demais diretrizes e políticas do FUTPRO aplicáveis, ou
					constantes da legislação local.
					<br />
					<br />
					3.7 O Usuário se obriga a não fazer uso dos Produtos do
					FUTPRO na forma de spam, abstendo-se, entre outras práticas,
					de: (i) enviar publicidade ou outros comunicados a outros
					usuários, sem a prévia solicitação ou o consentimento
					destes; e/ou (ii) remeter quaisquer outras mensagens não
					solicitadas, nem previamente consentidas, sob pena da
					aplicação de penalidades, dentre as quais o banimento da
					conta do Usuário.
					<br />
					<br />
					3.8 O Usuário concorda em indenizar o FUTPRO por todo e
					qualquer dano ou perda que possa eventualmente surgir
					decorrente do seu acesso e uso dos Produtos do FUTPRO ou por
					qualquer demanda promovida por outros usuários ou terceiros
					decorrentes da violação de qualquer cláusula destes Termos,
					de outras políticas e regras estabelecidas pelo FUTPRO, ou
					da legislação vigente.
					<br />
					<br />
					3.9 “Hiperlinks” e outros redirecionamentos eventualmente
					incluídos nos Produtos do FUTPRO por usuários não implicam,
					em nenhum caso, existência de relações entre o FUTPRO e o
					proprietário da Página na qual se estabeleça, nem a
					aceitação e aprovação por parte do FUTPRO de seus conteúdos
					ou serviços ali oferecidos ou disponibilizados ao público,
					não se responsabilizando o FUTPRO de maneira alguma por
					isso, mas sim o próprio Usuário.4.9.1 O FUTPRO incentiva os
					Usuários a denunciarem conduta que considerem violar seus
					direitos ou os Termos e as demais políticas do FUTPRO. ‍
					<br />
					<br />
					3.10 Ao usufruir dos Produtos do FUTPRO, o Usuário se
					compromete a:
					<br />
					<br />
					a) manter os dados cadastrais atualizados e verídicos;
					<br />
					b) manter em sigilo sua senha de acesso aos Produtos do
					FUTPRO, sem jamais compartilhar sua conta (login e senha);
					<br />
					c) não veicular conteúdos considerados ofensivos, abusivos,
					difamatórios, pornográficos, ameaçadores, obscenos, ilegais,
					ou que se destinem a promover ou cometer ato ilegal de
					qualquer natureza, que incluam conteúdo malicioso, tais como
					vírus, cavalos de troia ou malwares, ou que de qualquer
					forma interfiram no acesso de qualquer Usuário aos Produtos
					do FUTPRO;
					<br />
					d) não agredir, caluniar, injuriar ou difamar outros
					usuários;
					<br />
					e) não utilizar os Produtos do FUTPRO se estiver proibido de
					usá-los de acordo com as leis aplicáveis;
					<br />
					f) não utilizar indevidamente qualquer canal de denúncia ou
					recurso, como, por exemplo, não realizar denúncias ou
					recursos fraudulentos, duplicados ou sem fundamentos;
					<br />
					g) não atuar em conluio com terceiros, não usar vantagem ou
					influência injusta, incluindo a exploração de uma falha,
					brecha ou erro descoberto no software do FUTPRO, não
					realizar atividades fraudulentas em seu benefício, incluindo
					o uso de um cartão de crédito ou débito roubado, clonado ou
					de outra forma não autorizada como fonte de fundos, não
					participar em quaisquer atividades criminosas, não realizar
					a transferência de fundos de uma conta de um Usuário para
					outro;
					<br />
					h) não violar a legislação local, estando ciente de que é o
					responsável por cumprir a lei aplicável
					<br />
					<br />
					3.11 Ao encerrar sua conta, o Usuário também concorda em
					perder quaisquer bônus promocionais, prêmios ou benefícios
					que possa ter adquirido.
					<br />
					<br />
					3.12. No caso de encerramento da conta do Usuário devido ao
					vício em jogos de azar ou se a conta for encerrada devido a
					fraude, o Usuário reconhece que está proibido de abrir uma
					nova conta. O FUTPRO não será responsável se o Usuário
					conseguir abrir uma nova conta, por tal ação ou por
					quaisquer danos consequentes diretos ou indiretos
					decorrentes da criação da nova conta. O FUTPRO possui o
					direito de encerrar, a qualquer momento, uma nova conta
					aberta em violação à previsão deste item. A permissão para
					criar uma nova conta é fornecida a exclusivo critério do
					FUTPRO, e isso não significa nem implica que a ação
					disciplinar tenha sido errada ou sem causa.
					<br />
					<br />
					4. Garantias e Responsabilidades do FUTPRO
					<br />
					<br />
					4.1 Na máxima extensão permitida pela legislação aplicável,
					o FUTPRO se isenta de garantias de qualquer tipo, incluindo,
					porém não se limitando a garantias relacionadas à segurança,
					confiabilidade, conveniência e performance dos Produtos do
					FUTPRO.
					<br />
					<br />
					4.2 O FUTPRO se empenha em manter os Produtos do FUTPRO
					seguros e funcionando de forma apropriada, mas não pode
					garantir integralmente o acesso ou a disponibilidade
					ininterrupta dos Produtos do FUTPRO, em decorrência da
					instabilidade própria do ambiente virtual, estando os
					Produtos do FUTPRO, portanto, sujeitos a eventuais problemas
					de interrupção, falha técnica e indisponibilidade de
					funcionamento temporária.
					<br />
					<br />
					4.3 O FUTPRO não faz nenhuma representação, nem garante,
					endossa, assegura ou assume responsabilidade por quaisquer
					aplicativos de terceiros (ou seu conteúdo), pelo conteúdo
					postado por usuários, dispositivos ou quaisquer outros
					produtos ou serviços anunciados, promovidos ou oferecidos
					por terceiros por meio dos Produtos do FUTPRO, ou qualquer
					site vinculado por hiperlink. O FUTPRO não será
					responsabilizado por qualquer transação entre o Usuário e
					terceiros fornecedores do acima exposto.
					<br />
					<br />
					4.4 O FUTPRO não oferece diretamente os serviços de
					Instituição de Pagamento ou Instituição Financeira, nem se
					responsabiliza por quaisquer tipos de indisponibilidade ou
					inconsistências ocorridas no processamento de pagamentos
					online, pois não realiza diretamente o processamento dos
					meios de pagamento constantes dos Produtos do FUTPRO, que é
					executado por empresa específica dessa área.
					<br />
					<br />
					4.5 Na máxima extensão permitida pela legislação vigente, o
					FUTPRO não será responsável, e desde já o Usuário concorda
					em não responsabilizar o FUTPRO, por quaisquer danos ou
					perdas, de qualquer tipo, resultantes, direta ou
					indiretamente, de:
					<br />
					<br />
					a) Utilização, pelo Usuário, ou sua inabilidade em usar os
					Produtos do FUTPRO;
					<br />
					b) Atrasos ou interrupções nos Produtos do FUTPRO;
					<br />
					c) Falhas, bugs, inconsistências ou inexatidões de qualquer
					natureza nos Produtos do FUTPRO;
					<br />
					d) Ações, omissões ou conteúdo de outros usuários e de
					terceiros;
					<br />
					e) Aplicação de sanção em decorrência da violação de
					qualquer cláusula destes Termos e das demais políticas
					aplicáveis;
					<br />
					f) Quaisquer condutas ou acontecimentos que fujam da esfera
					de vigilância do FUTPRO;
					<br />
					g) Acesso não autorizado por terceiros na conta dos
					Usuários.
					<br />
					<br />
					4.6 Ao acessar os Produtos do FUTPRO, o Usuário deve estar
					ciente de que sua conexão com a Internet pode não ser tão
					segura quanto a de outros Usuários, o que pode afetar seu
					desempenho em eventos ao vivo, de tempo crítico, oferecidos
					nos Produtos do FUTPRO.
					<br />
					<br />
					4.7 Nas hipóteses previstas no item 5.7, acima, o FUTPRO se
					reserva o direito de (i) corrigir qualquer inconsistência de
					uma aposta ou palpite e reajustá-los pelos preços e termos
					que deveriam estar disponíveis no momento em que a aposta ou
					palpite foram feitos, e (ii) anular a aposta ou palpite nos
					casos em que a correção não seja possível.
					<br />
					<br />
					5. Propriedade Intelectual
					<br />
					<br />
					5.1 O FUTPRO concede ao Usuário uma permissão limitada, não
					exclusiva e revogável para fazer uso pessoal e não comercial
					dos Produtos do FUTPRO.
					<br />
					<br />
					5.2 O Usuário reconhece que o FUTPRO é o exclusivo titular
					dos direitos de propriedade intelectual e industrial
					associados aos Produtos do FUTPRO, os conteúdos de suas
					telas, programas, base de dados, redes, códigos, software,
					hardware, informação, tecnologia, funcionalidades, arquivos,
					marcas, patentes, direitos autorais, modelos industriais,
					nomes comerciais, dentre outros direitos protegidos pelas
					leis nacionais e internacionais em vigor.
					<br />
					<br />
					5.3 O Usuário não poderá fazer cópias não autorizadas dos
					Produtos do FUTPRO – no todo ou em parte –, uma vez que
					todos os direitos autorais incidentes (código fonte,
					interface, layout, “look and feel” e outros) são de
					propriedade exclusiva do FUTPRO.
					<br />
					<br />
					5.4 Não é permitido ao Usuário reconfigurar, desmontar ou
					praticar, de qualquer forma, engenharia reversa nos Produtos
					do FUTPRO. Também não é permitido ao Usuário: (i) remover,
					alterar, interferir, evitar ou de qualquer forma modificar
					marca d’água, copyright, símbolo, marca ou qualquer outro
					sinal indicativo de propriedade relativos aos Produtos do
					FUTPRO, ou quaisquer direitos e/ou mecanismos de proteção
					associados; (ii) copiar, fazer download, capturar,
					reproduzir, arquivar, distribuir, fazer upload, publicar,
					modificar, traduzir, exibir, transmitir, apropriar,
					incorporar ou comercializar qualquer conteúdo oferecido nos
					Produtos do FUTPRO; (iii) incorporar os conteúdos dos
					Produtos do FUTPRO a qualquer aplicativo de software ou
					hardware, exibir ou retransmitir através destes, ou torná-lo
					disponível através de frames ou links; (iv) distribuir,
					anunciar ou desenvolver um negócio a partir do conteúdo dos
					Produtos do FUTPRO; (v) utilizar os Produtos do FUTPRO ou
					parte deles, de qualquer forma, para a criação de obras
					derivadas ou nele baseadas, tais como montagens, mash-ups,
					vídeos similares e materiais de marketing ou merchandising,
					entre outros.
					<br />
					<br />
					5.5 Fica expressamente vedada qualquer prática que possa
					prejudicar a imagem do FUTPRO ou violar direitos deste sobre
					os conteúdos disponíveis nos Produtos do FUTPRO, danificar
					seus patrimônios, danificar ou de qualquer forma interferir
					no fluxo normal de comunicações com seus servidores, na
					segurança, inviolabilidade e privacidade dos dados lá
					armazenados e transmitidos.
					<br />
					<br />
					5.6 O Usuário não pode utilizar a marca e/ou quaisquer
					sinais distintivos utilizados pelo FUTPRO constantes dos
					Produtos do FUTPRO.
					<br />
					<br />
					5.7 Todos os direitos e licenças não concedidos
					expressamente nestes Termos ou nas demais políticas
					aplicáveis são reservados aos proprietários dos conteúdos ou
					serviços, vez que os presentes Termos e demais políticas
					aplicáveis não concedem quaisquer licenças implícitas.
					<br />
					<br />
				</div>
			</div>
			<Footer />
		</Layout>
	);
};
