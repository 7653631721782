import { useEffect } from 'react';
import { CurrencyFormatter } from '../../../helpers/utils';
import Button from '../../ui/button';
import Modal from '../modal';
import { App } from '@capacitor/app';

export const WithdrawPix = ({ open, onClose, value }: any) => {
	useEffect(() => {
		App.addListener('backButton', (event) => {
			if (open) {
				onClose();
			}
		});

		return () => document.body.classList.remove('overflow-hidden');
	}, [open]);

	return (
		<Modal
			id="WithdrawPix"
			open={open}
			onClose={() => {
				onClose();
			}}
		>
			<div className="flex flex-col gap-6 items-center">
				<p className="text-2xl font-bold text-center text-custom-gray2 font-bahnschrift">
					<p className="w-[310px] text-2xl text-center text-custom-gray2 font-bahnschrift">
						<span className="w-[310px] text-2xl font-bold text-center text-custom-gray2 font-bahnschrift">
							Saque Pix
						</span>
						<br />
						<span className="w-[310px] text-2xl text-center text-custom-gray2 font-bahnschrift">
							R$ {CurrencyFormatter(Number(value))}
						</span>
					</p>
				</p>
				<p className="w-11/12 text-sm text-center text-custom-gray2 font-bahnschrift">
					Solicitação de saque aceita com sucesso creditaremos R${' '}
					{CurrencyFormatter(Number(value))} na conta corrente do
					banco associado a sua chave pix e te avisaremos
				</p>

				<div className="w-full pt-2">
					<Button
						onClick={() => {
							onClose();
						}}
						className={'w-full h-12'}
					>
						Fechar
					</Button>
				</div>
			</div>
		</Modal>
	);
};
