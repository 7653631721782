import React, { createContext, useState, useContext, ReactNode } from 'react';
import Button from '../../components/ui/button';
import { Capacitor } from '@capacitor/core';
import ModalUpdateVersion from '../../components/Modal/modalUpdateVersion';
import { Warning } from '../../components/Icons/Warning';
import { isApk } from '../../helpers/version';

interface UpdateVersionContextType {
	openModalUpdateVersion: boolean;
	showModalUpdate: (data: any) => void;
}

const UpdateVersionContext = createContext<
	UpdateVersionContextType | undefined
>(undefined);

interface UpdateVersionProviderProps {
	children: ReactNode;
}

export const UpdateVersionProvider: React.FC<UpdateVersionProviderProps> = ({
	children,
}) => {
	const [openModalUpdateVersion, setOpenModalUpdateVersion] = useState(false);
	const [info, setInfo] = useState<{
		isUpdateMandatory: boolean;
		success: boolean;
		message: string;
	} | null>();

	const showModalUpdate = (data: any) => {
		setOpenModalUpdateVersion(true);
		setInfo(data);
	};
	const closeModalUpdate = () => {
		if (info && !info.isUpdateMandatory) {
			localStorage.setItem(
				'updateLater',
				JSON.stringify({ bypass: 'ok', date: new Date().getTime() })
			);
			setOpenModalUpdateVersion(false);
		}
	};

	const handleUpdateVersion = () => {
		const link = document.createElement('a');
		link.href =
			Capacitor.getPlatform() === 'ios'
				? 'https://apps.apple.com/br/app/futpro/id6502291694'
				: Capacitor.getPlatform() === 'android' && isApk
				? 'https://pga-images.s3.us-east-1.amazonaws.com/app/FUTPRO.apk'
				: 'https://play.google.com/store/apps/details?id=pga.app.play&pli=1';
		link.click();
	};

	return (
		<UpdateVersionContext.Provider
			value={{
				openModalUpdateVersion,
				showModalUpdate,
			}}
		>
			{children}

			{info && (
				<ModalUpdateVersion
					id="modalupdate"
					open={openModalUpdateVersion}
					onClose={closeModalUpdate}
				>
					<div className="flex flex-col gap-6 items-center">
						<Warning />

						<p className="text-2xl font-bold text-center w-96 text-custom-gray2 font-bahnschrift">
							Atualização disponível!
						</p>
						<p className="text-sm text-center text-custom-gray2 font-bahnschrift">
							Existe uma versão mais atualizada disponível
							{Capacitor.getPlatform() === 'ios'
								? ' na Apple Store'
								: Capacitor.getPlatform() === 'android' && isApk
								? ' da APK'
								: ' na Play Store'}
							.
						</p>

						<div className="w-full pt-2 flex flex-row gap-2">
							{!info.isUpdateMandatory && (
								<Button
									onClick={closeModalUpdate}
									variant="destructive"
									className={'w-full p-3'}
								>
									Atualizar depois
								</Button>
							)}
							<Button
								onClick={handleUpdateVersion}
								className={'w-full p-3'}
							>
								{info.isUpdateMandatory
									? 'Atualizar Agora'
									: 'Quero atualizar agora'}
							</Button>
						</div>
					</div>
				</ModalUpdateVersion>
			)}
		</UpdateVersionContext.Provider>
	);
};

export const useUpdateVersionContext = (): UpdateVersionContextType => {
	const context = useContext(UpdateVersionContext);
	if (context === undefined) {
		throw new Error(
			'useUpdateVersionContext must be used within a CookieProvider'
		);
	}
	return context;
};
