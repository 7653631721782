import { RiArrowDownSLine, RiArrowUpSLine } from '@remixicon/react';
import { useState, useEffect, forwardRef } from 'react';

export interface ISelectInputProps {
	options: Array<{ value: string; label: string }>;
	placeholder?: string;
	value?: string;
	onChange: (value: string) => void;
	error?: string;
	disabled?: boolean;
	autoFocus?: boolean;
	classNameCustom?: string;
}

const SelectInput = forwardRef<HTMLDivElement, ISelectInputProps>(
	(
		{
			options,
			placeholder,
			value,
			onChange,
			error,
			disabled,
			autoFocus,
			classNameCustom,
		},
		ref
	) => {
		const [isFocused, setIsFocused] = useState(false);
		const [isOpen, setIsOpen] = useState(false);

		useEffect(() => {
			if (autoFocus && ref && typeof ref !== 'function' && ref.current) {
				ref.current.focus();
			}
		}, [autoFocus, ref]);

		const toggleDropdown = () => {
			if (!disabled) setIsOpen(!isOpen);
		};

		const handleFocus = () => {
			setIsFocused(true);
		};

		const handleBlur = () => {
			setIsFocused(false);
		};

		const customSelectErrorColor = error
			? 'border-error focus:outline-none focus:ring-0 focus:border-error px-2.5 py-2.5 h-12 disabled:bg-custom-white'
			: 'border-custom-gray3 focus:outline-none focus:ring-0 focus:border-info px-2.5 pt-4 h-12 disabled:bg-custom-white';

		const customLabelErrorColor = error
			? 'text-error peer-focus:text-error disabled:bg-custom-white'
			: 'text-custom-gray3 peer-focus:text-custom-gray4 disabled:bg-custom-white';

		return (
			<div className="relative">
				<div
					className={`block peer w-full text-sm rounded-md border appearance-none font-bahnschrift ${customSelectErrorColor} ${classNameCustom}`}
					onClick={toggleDropdown}
					onFocus={handleFocus}
					onBlur={handleBlur}
					ref={ref}
					tabIndex={0}
				>
					{value || isFocused ? (
						<span className="p">
							{options.find((opt) => opt.value === value)?.label}
						</span>
					) : (
						<span className="text-gray-500"></span>
					)}

					<button className="absolute inset-y-0 right-0 z-40 flex items-center px-4 text-gray-400 cursor-pointer">
						{isOpen ? (
							<RiArrowUpSLine size={20} color="#A3A5AB" />
						) : (
							<RiArrowDownSLine size={20} color="#A3A5AB" />
						)}
					</button>
				</div>

				{isOpen && !disabled && (
					<div className="absolute z-10 w-full bg-white border border-gray-300 mt-2 rounded-md shadow-md max-h-60 overflow-y-auto">
						{options.map((option) => (
							<div
								key={option.value}
								className="p-2 cursor-pointer hover:bg-gray-200"
								onClick={() => {
									onChange(option.value);
									setIsOpen(false);
								}}
							>
								{option.label}
							</div>
						))}
					</div>
				)}

				<label
					htmlFor="select_filled"
					className={`absolute text-sm duration-300 transform font-bahnschrift -translate-y-4 scale-75 top-3.5 z-10 origin-[0] start-2.5 ${
						value || isFocused
							? 'scale-75 -translate-y-4'
							: 'scale-100 translate-y-0'
					} ${customLabelErrorColor}`}
					style={{ pointerEvents: 'none' }}
				>
					{placeholder}
				</label>

				{error && (
					<p className="flex text-error text-xs pl-1 font-bahnschrift pt-0.5">
						{error}
					</p>
				)}
			</div>
		);
	}
);

export default SelectInput;
