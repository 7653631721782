export const navigateResponsible = [
	{
		icon: 'limits',
		route: '/my-profile/change-selflimits',
		title: 'Limites pessoais',
		description: 'Defina limites pessoais para um jogo responsável.',
	},
	{
		icon: 'lock',
		route: '/my-profile/lock-account',
		title: 'Pausa ou período de afastamento',
		description: 'Clientes podem dar um tempo de jogar a qualquer momento.',
	},
	{
		icon: 'close',
		route: '/my-profile/close-account',
		title: 'Autoexclusão',
		description:
			'Autoexclusão envolve um compromisso conjunto nosso e do cliente.',
	},
];

export const guidelinesResponsible = [
	{
		position: 1,
		title: 'RESPONSABILIDADE PERANTE NOSSOS JOGADORES',
		description:
			'Valorizamos nossos jogadores, respeitamos suas expectativas de sorte e nos orgulhamos de ser a plataforma escolhida para o entretenimento de cada um deles com apostas esportivas e jogos online',
	},
	{
		position: 2,
		title: 'PREVENÇÃO E PROTEÇÃO',
		description:
			'Estamos comprometidos em proteger o bem-estar dos nossos jogadores. Nosso objetivo é prevenir problemas de dependência de jogos e garantir que menores de idade não participem de atividades de apostas. Utilizamos sistemas avançados de verificação de idade para assegurar a conformidade. Também alertamos contra o jogo sob a influência de substâncias psicoativas, tristeza ou depressão, pois isso pode impactar negativamente as decisões.',
	},
	{
		position: 3,
		title: 'INCENTIVO AO JOGO RESPONSÁVEL E CONTROLE DO USUÁRIO',
		description:
			'Incentivamos o jogo responsável e fornecemos orientação sobre práticas saudáveis de jogo. Oferecemos ferramentas como limites de depósito, pausas temporárias e autoexclusão. Também disponibilizamos apoio psicológico e encaminhamentos para serviços especializados quando necessário.',
	},
	{
		position: 4,
		title: 'SINAIS DE ALERTA E AUTOAVALIAÇÃO',
		description:
			'Apresentamos sinais de alerta para identificar comportamentos problemáticos no jogo e incentivamos a autoavaliação regular do tempo e dinheiro gastos em apostas.',
	},
	{
		position: 5,
		title: 'MONITORAMENTO E EQUILÍBRIO',
		description:
			'Recomendamos o monitoramento da atividade de jogo e o equilíbrio com outras formas de entretenimento. Incentivamos pausas regulares para evitar excessos e promover um estilo de vida equilibrado',
	},
	{
		position: 6,
		title: 'TRANSPARÊNCIA E EDUCAÇÃO',
		description:
			'Incentivamos o entendimento das chances de ganhar e fornecemos informações claras sobre as probabilidades de vitória e os riscos envolvidos. Também promovemos a busca de suporte profissional em caso de necessidade para lidar com questões relacionadas ao jogo.',
	},
	{
		position: 7,
		title: 'TREINAMENTO DE FUNCIONÁRIOS',
		description:
			'Investimos no treinamento contínuo de nossos funcionários para que possam identificar e lidar com sinais de problemas de jogo entre os jogadores. Funcionários bem treinados são capazes de fornecer suporte eficaz e direcionar os jogadores para os recursos de apoio disponíveis.',
	},
	{
		position: 8,
		title: 'CONFORMIDADE LEGAL',
		description:
			'Cumprimos rigorosamente todas as leis e regulamentações aplicáveis ao setor de jogos on-line e apostas. Nossa conformidade legal garante que operamos de maneira ética e responsável, proporcionando um ambiente de jogo seguro e regulamentado para nossos jogadores.',
	},
	{
		position: 9,
		title: 'FEEDBACK DOS JOGADORES',
		description:
			'Valorizamos o feedback dos nossos jogadores sobre a eficácia das políticas de jogo responsável que adotamos. Com esse feedback, promovemos ajustes e melhoramos continuamente nossa Política de Jogo Responsável, garantindo que atenderemos às necessidades e preocupações de todos os nossos jogadores',
	},
	{
		position: 10,
		title: 'PARCERIAS COM ORGANIZAÇÕES DE APOIO',
		description:
			'Estabelecemos parcerias com organizações especializadas em tratamento de dependência de jogos para oferecer suporte adicional aos jogadores. Essas parcerias permitem que nossos jogadores tenham acesso a recursos atualizados e ajuda profissional de qualidade.',
	},
];
