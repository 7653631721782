import { Clipboard } from '@capacitor/clipboard';
import { toast } from 'sonner';
import Toast from '../components/ui/toast';
import {
	RiFacebookFill,
	RiInstagramLine,
	RiLinkedinFill,
	RiThreadsLine,
	RiTiktokLine,
	RiTwitterXFill,
	RiWhatsappLine,
} from '@remixicon/react';
import { Balance } from '../components/Icons/Balance';
import { Dump } from '../components/Icons/Dump';
import { PadLock2 } from '../components/Icons/PadLock2';

export const copyToClipboard = async (data: any) => {
	try {
		await Clipboard.write({ string: data });
		toast(<Toast variant="success">Copiado com sucesso!</Toast>);
	} catch (err) {
		console.log('Falha ao copiar o texto', err);
		toast(<Toast variant="error">Falha ao copiar.</Toast>);
	}
};

export const shared = [
	{
		title: 'Whatsapp',
		icon: <RiWhatsappLine className="text-[#4b4b4b]" />,
	},
	{
		title: 'Instagram',
		icon: <RiInstagramLine className="text-[#4b4b4b]" />,
	},
	{
		title: 'TikTok',
		icon: <RiTiktokLine className="text-[#4b4b4b]" />,
	},
	{
		title: 'X',
		icon: <RiTwitterXFill className="text-[#4b4b4b]" />,
	},
	{
		title: 'Threads',
		icon: <RiThreadsLine className="text-[#4b4b4b]" />,
	},
	{
		title: 'Facebook',
		icon: <RiFacebookFill className="text-[#4b4b4b]" />,
	},
	{
		title: 'Linkedin',
		icon: <RiLinkedinFill className="text-[#4b4b4b]" />,
	},
];

export const showIcon = (icon: string) => {
	switch (icon) {
		case 'limits':
			return <Balance />;
		case 'lock':
			return <PadLock2 />;
		case 'close':
			return <Dump />;
		default:
			return null;
	}
};
