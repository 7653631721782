import { App } from '@capacitor/app';
import { useEffect } from 'react';

type ModalProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
};

const ModalUpdateVersion = ({ open, onClose, children, id }: ModalProps) => {
	useEffect(() => {
		const scrollTop = window.scrollY || document.documentElement.scrollTop;
		if (open) {
			document.body.classList.add('overflow-hidden');
			document.body.style.top = `-${scrollTop}px`;
			document.body.style.overflow = 'unset';
			document.body.style.position = 'fixed';
			document.body.style.width = '100%';
			document.getElementById('launcher')?.classList.add('hidden');
		} else {
			document.body.classList.remove('overflow-hidden');
			document.body.style.top = '';
			document.body.style.overflow = 'hidden';
			document.body.style.position = '';
			document.body.style.width = '';
			document.getElementById('launcher')?.classList.remove('hidden');
		}
		App.addListener('backButton', (event) => {
			if (open) {
				onClose();
			}
		});

		return () => document.body.classList.remove('overflow-hidden');
	}, [open]);

	return (
		<div
			id={id}
			className={`fixed inset-0 flex justify-center items-center transition-colors z-50 ${
				open ? 'visible bg-custom-gray1/70' : 'invisible'
			}`}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={`w-full lg:max-w-96 lg:mx-auto mx-4 bg-custom-white rounded-xl shadow p-6 transition-all ${
					open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
				}`}
			>
				{children}
			</div>
		</div>
	);
};

export default ModalUpdateVersion;
